/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit"
import newRecordApi from "apis/newRecordApi"
import { handleAsyncRequest } from 'utils/helper'

export const getTopNewRecord  = createAsyncThunk(
  'newRecord/getTopNewRecord',
  async (params: any) => {
    const [error, result] = await handleAsyncRequest(newRecordApi.getTopNewRecord(params))
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)
