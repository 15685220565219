import { Link, useLocation, useOutlet } from 'react-router-dom'
import { TitleTabs } from 'models/schoolarship'
import { TwoLineTitle } from 'shared'
import TopHorseUserStyled from './styled'

interface IHorseTrackProps {
  titleTabs: TitleTabs[]
}

function GuildMain({ titleTabs }: IHorseTrackProps) {
  const { pathname: currentPathname } = useLocation()
  const outlet = useOutlet()


  return (
    <TopHorseUserStyled>
      <div className='container'>
        <div className='title-tabs-container'>
          <div className='title-tabs d-flex justify-content-center align-items-start'>
            {titleTabs.map(tab =>
              tab.link === currentPathname ? (
                <Link key={tab.name} to={tab.link} className='tab-link text-center'>
                  <TwoLineTitle text={tab.name} />
                </Link>
              ) : (
                <Link key={tab.name} to={tab.link} className='tab-link font-bold text-uppercase text-center'>
                  {tab.name}
                </Link>
              )
            )}
          </div>
        </div>
        <div className='content'>{outlet}</div>
      </div>
    </TopHorseUserStyled>
  )
}

export default GuildMain
