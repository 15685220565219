/* eslint-disable @typescript-eslint/no-explicit-any */
import { HORSE_POPUP_ARMOR } from 'assets/images'
import styled from 'styled-components'

interface JackpotStyleProps {
  width?: any
  height?: any
}

const JackpotDetailStyle = styled.div<JackpotStyleProps>`
  position: relative;
  width: ${({ width }) => width + 'px'};
  min-height: ${({ height }) => height + 'px'};
  background: url(${HORSE_POPUP_ARMOR});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 99;
  padding: 25px 32px 30px;

  .close-btn {
    top: 29px;
    right: 29px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 100;
  }

  .content-box {
    position: relative;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 18px;
    line-height: 38px;
    padding: 22px;

    .information-box {
      display: flex;
      gap: 15px;
      font-size: 18px;
      align-items: center;

      .active {
        position: relative;
        color: #4ef076;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        .line-text {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }

    .content_jackpot {
      margin-left: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 15px;

      .img_curency {
        margin-left: 5px;
      }
    }

    .content_armor {
      display: flex;
      align-items: center;
      margin-right: 10px;
      width: 32%;
      margin-bottom: 5px;
    }

    .img_pool_reward {
      display: flex;
      align-items: center;
      position: relative;
      width: 30px;
      height: 30px;
      margin-left: 5px;
    }

    .img_armor {
      display: flex;
      align-items: center;
      position: relative;
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }

    .content_jackpot_detail {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      min-height: 50px;
      max-height: 200px;
      overflow-y: auto;

      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
      }
      ::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background: rgba(0,0,0,0.2);
      }
      ::-webkit-scrollbar-thumb:hover{
        background: rgba(0,0,0,0.4);
      }
      ::-webkit-scrollbar-thumb:active{
        background: rgba(0,0,0,.9);
      }

      .row_jackpot_detail {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        padding: 5px;
        border: 1px solid transparent;
        border-image: linear-gradient(90deg, rgba(20,39,83,1) 0%, rgba(102,255,253,1) 50%, rgba(25,23,91,1) 100%);
        border-image-slice: 1;
        border-radius: 8px;
        margin-bottom: 5px;

        .image_jackpot {
          width: 90px;
          height: 90px;
          background-color: #151825;
          padding: 2px;
          margin-right: 20px;
          clip-path: polygon(0% 0%, 86% 0, 100% 15%, 100% 100%, 0% 100%);
        }

        .padding_img {
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .padding_img_coin {
          padding: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .boder_img {
          width: max-content;
          height: 100%;
        }

        .name_jackpot {
          height: 28px;
          line-height: 28px;

          .title_name {
            color: #fff566;
            display: inline-block;
            width: 70px;
          }
        }
       
        .rarity_jackpot {
          height: 22px;
          display: flex;
          align-items: center;

          .title_rarity {
            color: #fff566;
            display: inline-block;
            width: 70px;
          }
        }

        .image_rarity {
          width: 20px;
          height: 20px;

          img {
            display: block;
          }
        }
      }
    }

    .non_reward {
      width: 100%;
      height: 100px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      border: 1px solid transparent;
      border-image: linear-gradient(90deg, rgba(20,39,83,1) 0%, rgba(102,255,253,1) 50%, rgba(25,23,91,1) 100%);
      border-image-slice: 1;
    }

    .img_doping {
      transform: scale(1.8);
    }

    .img_jackpot {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }

    .img_new_record {
      width: 40px;
      height: auto;
      cursor: pointer;
    }
  }

  .loading__item {
    position: absolute;
    top: 50%;
    left: 50%;

    .ant-spin {
      .ant-spin-dot-item {
        background-color: #4bf296;
      }
    }
  }

  .attribute__item {
    display: inline-block;
    min-width: 66px;
  }
`

export { JackpotDetailStyle }
