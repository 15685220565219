/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { links } from 'apps';
import { Link } from 'react-router-dom';
import { handleCareer, nFormatterK, shortenUserName } from 'utils/helper';
import VipIcon from 'features/components/VipIcon';
import { NEW_RECORD, VIEW_DOPING } from 'assets/images';
import HorseAvatar from 'features/Horse/components/HorseItem/HorseAvatar'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import InfoBoxContainer from './styled';
import { ClassTag } from 'shared';
import { getImageGender, getImageRegion } from 'utils/getLinkImage';
import ImageArmorSkin from 'features/components/ImageArmorSkin';
import { STRING_ARMOR_SKIN } from 'apps/constants';

const InfoBox = ({ data }: any) => {
  const isHorseVip = data?.horse?.msp_details?.msp;
  const styleFirstCount = data?.horse?.career?.first_count || 0
  const styleSecondCount = data?.horse?.career?.second_count || 0
  const styleThirdCount = data?.horse.career.third_count || 0
  const [horseHead, setHorseHead] = useState('')
  const [horseBody, setHorseBody] = useState('')
  const [horseLeg, setHorseLeg] = useState('')
  const [horseSkin, setHorseSkin] = useState('')

  useEffect(() => {
    if (data) {
      data?.horse?.weapon_items_equipped?.map((item: any) => {
        switch (item?.body_part) {
          case STRING_ARMOR_SKIN.HEAD: {
            return setHorseHead(STRING_ARMOR_SKIN.HEAD)
          }
          case STRING_ARMOR_SKIN.BODY: {
            return setHorseBody(STRING_ARMOR_SKIN.BODY)
          }
          case STRING_ARMOR_SKIN.LEG: {
            return setHorseLeg(STRING_ARMOR_SKIN.LEG)
          }
          case STRING_ARMOR_SKIN.SKIN: {
            return setHorseSkin(STRING_ARMOR_SKIN.SKIN)
          }
          default:
            break;
        }
      })
    }
  }, [data])

  return (
    <InfoBoxContainer>
      <div className='content-box'>
        <div className='img-box'>
          <HorseAvatar
            horse={data?.horse}
            name={data?.horse?.name}
            avatar={data?.horse?.avatar}
            items={data?.horse?.weapon_items_equipped}
          />

          <Button className='button-detail'>
            <Link to={links.horse.detail(data?.horse?.id)}>Detail</Link>
          </Button>
        </div>
        <div className='info'>
          <div className='row-info'>
            <div className='name'>{data?.horse?.name}</div>
            <div className='horse-gender'>
              <div className='horse-gender__img'>
                <MemoizedLoadImageCommon srcImg={getImageGender(data?.horse?.gender)} />
              </div>
              <div className='region'>
                <MemoizedLoadImageCommon srcImg={getImageRegion(data?.horse?.region)} />
              </div>
              {/* new record */}
              {data?.horse?.is_new_record ? <div className='img_new_record'>
                <MemoizedLoadImageCommon srcImg={NEW_RECORD} />
              </div> : ""}
            </div>
          </div>
          <div className='row-info'>
            <ClassTag text={data?.horse?.racing_class ?? ''} isActive={true} />
            {isHorseVip && <VipIcon />}
            {data?.horse.equipped_boost_item && (
              <div className='used-doping'>
                <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />
              </div>
            )}
          </div>

          <div className='row-info first'>
            <span>Token id:</span>
            <span>{data?.horse?.token_id}</span>
          </div>
          <div className='row-info thrid'>
            <span>Renter:</span>
            <span className='text__ellipsis color__white'>{data?.lending_info?.renter?.name ? shortenUserName(data?.lending_info?.renter?.name) : '---'}</span>
          </div>
          <div className='row-info thrid'>
            <span>Owner:</span>
            <span className='text__ellipsis'>{shortenUserName(data?.horse?.owner?.name)}</span>
          </div>
          <div className='row-info thrid'>
            <span>Career:</span>
            <span className='color__white'>
              <span className='color__fff566'>
                {nFormatterK(+data?.horse?.career?.total_number_of_races, 3)}
              </span>
              {` ${styleFirstCount}/${styleSecondCount}/${styleThirdCount}`}
            </span>
          </div>
          <div className='row-info thrid'>
            <span>Win rate:</span>
            <span className='text__ellipsis color__white'>{handleCareer(data?.horse?.career)}</span>
          </div>

          <div className='profile__image__armor'>
            <ImageArmorSkin
              horseHead={horseHead}
              horseBody={horseBody}
              horseLeg={horseLeg}
              horseSkin={horseSkin}
              weaponItemsEquipped={data?.horse?.weapon_items_equipped || []}
              width={30}
              height={30}
            />
          </div>
        </div>
      </div>
    </InfoBoxContainer>
  )
}

export default InfoBox;