/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, Route, Routes } from 'react-router-dom'

import { constants, links, paths } from 'apps'
import { useLocalStorage } from 'hooks'
import { CommonLayout } from 'layouts'
import { useEffect } from 'react'
import { PublicRoute } from 'routes'
import { handleAsyncRequest } from 'utils/helper'
import { getSigner } from 'utils/metamask'
import { TopHorses, TopUsers, RankingMain } from './pages'
import TopRecordRanking from './pages/TopRecordRanking'
import { STRING_REGION } from 'apps/constants'

const tabs = [
  {
    name: 'Top Horses',
    link: links.ranking.topHorses()
  },
  {
    name: 'Top Users',
    link: links.ranking.topUsers()
  }
]

const tabsRecord = [
  {
    name: 'JAPAN',
    link: links.ranking.topRecordJapan()
  },
  {
    name: 'KOREA',
    link: links.ranking.topRecordKorea()
  }
]

function SpecialRace() {
  const [, setUserId] = useLocalStorage(constants.USER_ID_KEY, 0)
  const [, setAccessToken] = useLocalStorage(constants.ACCESS_TOKEN_KEY, null)
  const [, setRefreshToken] = useLocalStorage(constants.REFRESH_TOKEN_KEY, null)

  const checkSignerProfile = async () => {
    const [signerError] = await handleAsyncRequest(getSigner())
    if (signerError) {
      setUserId(0)
      setAccessToken('')
      setRefreshToken('')
    }
  }
  useEffect(() => {
    checkSignerProfile()
  }, [])
  return (
    <Routes>
      <Route
        path={paths.default()}
        element={
          <PublicRoute layout={CommonLayout}>
            <RankingMain titleTabs={tabs} />
          </PublicRoute>
        }
      >
        <Route path={paths.ranking.topHorses()} element={<TopHorses />} />
        <Route path={paths.ranking.topUsers()} element={<TopUsers />} />
      </Route>
      <Route
        path={paths.default()}
        element={
          <PublicRoute layout={CommonLayout}>
            <RankingMain titleTabs={tabsRecord} />
          </PublicRoute>
        }
      >
        <Route path={paths.ranking.topRecordJapan()} element={<TopRecordRanking region={STRING_REGION.TOKYO} />} />
        <Route path={paths.ranking.topRecordKorea()} element={<TopRecordRanking region={STRING_REGION.SEOUL} />} />
      </Route>
      <Route path='*' element={<Navigate replace to={paths.notFound.feature()} />} />
    </Routes>
  )
}


export default SpecialRace
