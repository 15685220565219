import { FARMES_HORSE } from 'assets/images'
import styled from 'styled-components'

const InforHorseWinnerModalStyled = styled.div`
  .winner-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .winner-background {
      background-repeat: no-repeat;
      background-size: contain;

      .winner-top {
        z-index: 1;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin-top: 265px;
        .first-winner {
          z-index: 1;
          height: 125px;
        }
        .avatar-horse {
          background-image: url(${FARMES_HORSE});
          background-repeat: no-repeat;
          background-size: 678px 588px;
          width: 678px;
          height: 595px;
          position: relative;
        }

        .avatar {
          width: 50%;
          height: 52%;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 55%;
          transform: translate(-50%, -50%);
        }
      }
      .infor-horse {
        z-index: 1;
        color: white;
        padding: 75px;
        position: relative;
        top: -203px;

        .name {
          display: flex;
          justify-content: center;
          font-size: 32px;
          line-height: 27px;
          color: #fbbc00;
          margin-bottom: 5px;
        }

        .info {
          margin-left: 25px;

          .lable {
            display: flex;
            font-size: 18px;
            margin-bottom: -4px;
            .owner {
              width: 40%;
            }
            .win-rate {
              width: 35%;
            }
            .career {
              width: 25%;
            }
          }

          .infor-horse-infor {
            display: flex;
            color: #fbbc00;
            font-size: 18px;

            .owner {
              width: 40%;

              .text-ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .win-rate {
              width: 35%;
            }
            .career {
              width: 25%;
            }
          }
        }
      }

      .box_win_jackpot {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;

        .img_win_jackpot {
          width: 150px;
          height: 150px;
        }

        .img_new_record {
          width: 120px;
          height: auto;
        }
      }
    }
  }
`

export default InforHorseWinnerModalStyled
