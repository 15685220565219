/* eslint-disable @typescript-eslint/no-explicit-any */
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'

import { ListContainer } from '../../pages/styled'
import HorseItem from 'features/Ranking/components/HorseItem'
import { HorseRank } from 'models'

const TopList = ({
  data,
  handlePageClick,
  pageCount,
  paramsSearch,
  numberHorse
}: any) => {
  const { t } = useTranslation()

  return (
    <ListContainer>
      <div className='my-horse-container'>
        <div className='d-flex header-horse text-uppercase'>
          <span className='horse width-15'>{t(`${NOTIFICATION_MESSAGE}.rank`)}</span>
          <span className='name width-21 text_align_left'>{t(`${NOTIFICATION_MESSAGE}.rankHorseName`)}</span>
          <span className='name width-3'></span>
          <span className='name width-3'></span>
          <span className='name width-3'></span>
          <span className='name width-5'></span>
          <span className='blood-line width-20 text_align_left'>{t(`${NOTIFICATION_MESSAGE}.owner`)}</span>
          <span className='gender width-15 text_align_left'>{t(`${NOTIFICATION_MESSAGE}.career`)}</span>
          <span className='classes width-15 text_align_left'>{t(`${NOTIFICATION_MESSAGE}.winRate`)}</span>
        </div>
        <table className='my-horse-table'>
          {data?.length === 0 ?
            (
              <span className='no_horse_record'>
                <span className='color-white font-size-24'>
                  No data found
                </span>
              </span>
            )
            : ""
          }
          {data?.length > 0 && (
            <tbody>
              {data?.map((horse: HorseRank, index: number) => {
                return (
                  <HorseItem
                    key={index}
                    horse={horse}
                  />
                )
              })}
            </tbody>
          )}
        </table>
        {data.length > 0 && parseInt(numberHorse) > 5 && (
          <div className='paginate'>
            <ReactPaginate
              nextLabel='>'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel='<'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              forcePage={paramsSearch.page ? +paramsSearch.page - 1 : 0}
            />
          </div>
        )}
      </div>
    </ListContainer>
  )
}

export default TopList
