/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import horseApi from 'apis/horseApi'
import userApi from 'apis/userApi'
import constants, {
  CURRENCY_TYPE,
  MAX_HORSE_LEVEL,
  REGEX_FIRST_SPACE,
  REGEX_LAST_SPACE,
  REGEX_NICK_NAME,
  REGEX_TWO_SPACE,
  STATS_TYPE,
  STRING_ARMOR_SKIN,
  STRING_BLOODLINE_CHARACTER,
  STRING_REGION,
  TIME_CLOSE_MODAL,
  TIME_CLOSE_STATS_MODAL
} from 'apps/constants'
import {
  CHANGE_EDIT_ICON,
  DISABLE_LEVEL_PLUS,
  ENABLE_LEVEL_PLUS,
  GAME_TOKEN_KUDA,
  ICON_BORROW,
  POPUP_EXP,
  SHADOW_DOPING,
  UNION,
  ICON_DOPING,
  VIEW_DOPING,
  GAME_TOKEN_MERAH,
  NEW_RECORD
} from 'assets/images'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import { AbilityBoxItem, AttributeBox, EnergyBar, HorseCareerItem, StatsBar } from 'features/Horse/components'
import EnergyExpBar from 'features/Horse/components/EnergyExpBar'
import HorseModalConfirm from 'features/Horse/components/HourseModalConfirm'
import HourseModalConfirmLevelUp from 'features/Horse/components/HourseModalConfirmLevelUp'
import SelectItemModal from 'features/Horse/components/SelectItemModal'
import { ResultHorseModal } from 'features/Race/components'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import Animation from 'features/components/Animation'
import { useAppDispatch, useLocalStorage, useToggle, useAppSelector } from 'hooks'
import { LENDING_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import {
  ApiResponse,
  Career,
  CurrentUser,
  GUILD_STATUS,
  GetHorseCareerListParams,
  GetHorseCareerResponse,
  Horse,
  HorseAbility,
  HorseCareer,
  HouseFeeChangeName,
  HouseStats,
  LENDING_STATUS,
  Level,
  ListHorseStats,
  OWNER_STATUS,
  OWN_STATUS,
  UpdateStatsParams,
  ListBoostItemsEquipped,
  ListBootsType,
  FeeResetPointChangeName
} from 'models'
import { ClassTag } from 'shared'
import Button from 'shared/Button'
import ButtonResetPoint from 'shared/ButtonResetPoint'
import Input from 'shared/Input'
import OneLineTitleClone from 'shared/OneLineTitleClone'
import { checkFormatId, getGuildTag, handleAsyncRequest, hanldeHorseOwnerName, sortAbilities } from 'utils/helper'
import { getCurrentUser } from 'utils/metamask'
import openNotification from 'utils/toast'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import VipIcon from 'features/components/VipIcon'
import ItemBox from 'features/Horse/components/HorseItem/ItemBox'
import HorseAvatar from 'features/Horse/components/HorseItem/HorseAvatar'
import RemoveItemModal from 'features/Horse/components/RemoveItemModal'
import ErrorModal from 'features/Horse/components/ErrorModal'
import { getCharacteristic, getColorHorse, getImageBloodline, getImageGender, getImageRegion, getRunType } from 'utils/getLinkImage'
import HorseDetailStyled from './styled'
import ImageArmorSkin from 'features/components/ImageArmorSkin'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'

const careerListParams: Omit<GetHorseCareerListParams, 'horseId'> = {
  limit: 100,
  page: 1,
  sort: ['created-desc']
}
const defaultStats = [
  { stats_type: 'SPEED', point: 0 },
  { stats_type: 'MUSCLE', point: 0 },
  { stats_type: 'STAMINA', point: 0 },
  { stats_type: 'AGILITY', point: 0 },
  { stats_type: 'SPIRIT', point: 0 },
  { stats_type: 'IQ', point: 0 }
]

const headerRaces = [
  'Time',
  'Race name',
  'Race course',
  'Class',
  'Field type',
  'Distance',
  'Rank',
  'Entry fee',
  'Race pool'
]

function HorseDetail() {
  const dispatch = useAppDispatch()
  const { coinUser } = useAppSelector(state => state)

  const defaultParams: UpdateStatsParams[] = defaultStats
  const { horse_id, contract, token_id } = useParams<string>()
  const [horseCareerList, setHorseCareerList] = useState<HorseCareer[]>([])
  const [horse, setHorse] = useState<Horse>()
  const [params, setParams] = useState<UpdateStatsParams[]>(defaultParams)
  const [firstTime, setFirstTime] = useState(0)
  const [disableRecovery, setDisableRecovery] = useState<boolean>(false)
  const [levelUp, setLevelUp] = useState<number>(0)
  const [winRate, setWinRate] = useState<string>('')
  const [carrer, setCarrer] = useState<string>('')
  const [point, setPoint] = useState<number>(0)
  const [tmpPoint, setTmpPoint] = useState<number>(0)
  const [totalExpHorse, setTotalExpHorse] = useState<number>(0)
  // const [messageError, setMessageError] = useState<string>('')
  const [horses, setHorses] = useState<ListHorseStats[]>([])
  const [horsesStats, setHorsesStats] = useState<HouseStats[]>([])
  const [using, setUsing] = useState<number>(0)
  const [expCurrent, setExpCurrent] = useState<number>(0)
  const [isModalOpen, toggleIsModalOpen] = useToggle(false)
  const [isModalOpenReset, toggleIsModalOpenReset] = useToggle(false)
  const [isSelectItemModal, toggleIsSelectItemModal] = useToggle(false)
  const [itemOffset, setItemOffset] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [currentItems, setCurrentItems] = useState<HorseCareer[]>([])
  const [horseId, setHorseId] = useState<any>(0)
  const [currentUser, setCurrentUser] = useState<CurrentUser>()
  const [canLevelUp, setCanLevelUp] = useState<boolean>(false)
  const [isOpenModalConfirmPickUpLevel, setModalConfirmPickUpLevel] = useState<boolean>(false)
  const [levelHorse, setLevelHorse] = useState<Level>()
  const [animation, setAnimation] = useState(false)
  const [isModalEditName, toggleIsModalEditName] = useToggle(false)
  const [isModalEditNameConfirm, toggleIsModalEditNameConfirm] = useToggle(false)
  const [isModalResetPointConfirm, toggleIsModalResetPointConfirm] = useToggle(false)
  const [horseName, setHorseName] = useState('')
  const [horseNameMessage, setHorseNameMessage] = useState('')
  const [isModalResultHorseOpen, toggleIsModalResultHorseOpen] = useToggle(false)
  const [messageErrorLevelUp, setMessageErrorLevelUp] = useState<string>('')
  const [getFeeHorse, setGetFeeHorse] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const [accessToken,] = useLocalStorage(constants.ACCESS_TOKEN_KEY, null)
  const [currentStatsSelected, setCurrentStatsSelected] = useState<string>('')
  const [currentStatsSelectedTemp, setCurrentStatsSelectedTemp] = useState<string>('')
  const [currentPosition, setCurrentPosition] = useState<string>('')
  const [currentIdRemove, setCurrentIdRemove] = useState<string>('')
  const [bootsTypeListDetail, setBootsTypeDetail] = useState<any>()
  const [listItemsEquipped, setListItemsEquipped] = useState<ListBoostItemsEquipped[]>([])
  const [isModalRemoveItemOpen, toggleIsRemoveItemOpen] = useToggle(false)
  const [itemDetail, setItemDetail] = useState<ListBootsType[]>([])
  const [isChangeItem, toggleIsChangeItem] = useToggle(false)
  const [isError, toggleIsError] = useToggle(false)
  const messageErrorRef = useRef('')
  const [horseHead, setHorseHead] = useState('')
  const [horseBody, setHorseBody] = useState('')
  const [horseLeg, setHorseLeg] = useState('')
  const [horseSkin, setHorseSkin] = useState('')
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [enoughPoint, setEnoughPoint] = useState(false)
  const [changeCurrency, setChangeCurrency] = useState(false)
  const [disableElement, setDisableElement] = useState(false)
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false)

  const isHorseVip = horse?.msp_details?.msp;
  const responseHorseDetail = (error: any = '', horseResponse: any = '') => {
    if (error?.code === 400 && error?.message === 'Horse NFT does not exist.') {
      messageErrorRef.current = error?.message || ''
      toggleIsError(true)
      return
    }

    if (horseResponse) {
      const fetchedHorse = horseResponse.data
      setHorseId(() => fetchedHorse?.id)
      setHorse(() => fetchedHorse)
    }
  }

  const fetchHorse = async () => {
    setDisableElement(true)
    if (horse_id) {
      const [error, horseResponse]: any = await handleAsyncRequest<ApiResponse<Horse>>(
        horseApi.getHorseDetailByTokenId({ horse_id })
      )    
  
      responseHorseDetail(error, horseResponse)
    } else if (contract && token_id) {
      const [error, horseResponse]: any = await handleAsyncRequest<ApiResponse<Horse>>(
        horseApi.getHorseDetailByHorseNFT(contract, { token_id })
      )    
  
      responseHorseDetail(error, horseResponse)
    }
    setDisableElement(false)
  }

  const useItemDopingSucess = () => {
    fetchHorse()
    pushNotification(t(`${NOTIFICATION_MESSAGE}.successfullyBootsItems`), true)
    toggleIsSelectItemModal(false)
    toggleIsChangeItem(false)
    setCurrentStatsSelected('')
  }

  // const fetchSyncHorse = async () => {
  //   messageErrorRef.current = ''
  //   if (horse_id) {
  //     const [error,]: any = await handleAsyncRequest(userApi.getSyncHorseDetail(horse_id))
  //     if (error?.code === 400 && error?.message === 'Horse NFT does not exist.') {
  //       messageErrorRef.current = error?.message || ''
  //       toggleIsError(true)
  //     }
  //   }
  // }

  const fetchHorseFee = async () => {
    const [error, horseFeeResponse]: any = await handleAsyncRequest<ApiResponse<HouseFeeChangeName>>(
      horseApi.getHouseFee()
    )

    if (error) {
      toast.error(<div>{error?.message}</div>, {
        position: 'bottom-right',
        autoClose: TIME_CLOSE_MODAL,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      toggleIsModalEditName(false)
    }

    if (horseFeeResponse) {
      const fetchedHorse = horseFeeResponse.data
      setGetFeeHorse(fetchedHorse)
    }
  }

  useEffect(() => {
    if (horse_id) {
      checkFormatId(horse_id || '')
      // if (accessToken) {
      //   fetchSyncHorse()
      // }
    } else if (contract && token_id) {
      checkFormatId(token_id || '')
    }
    fetchHorse()
  }, [horse_id, contract, token_id])

  const fetchCurrentUser = async () => {
    const [, currentUser] = await handleAsyncRequest(getCurrentUser())
    setCurrentUser(currentUser)
  }

  const fetchKudaToken = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return
    dispatch(setCoinUser(resultCoinUser.data))
  }

  const fetchHorseStats = async () => {
    const [, horseStatsResponse]: any = await handleAsyncRequest<ApiResponse<HouseStats>>(horseApi.getHouseStats())

    if (horseStatsResponse) {
      const fetchedHorsStats = horseStatsResponse.data
      setHorsesStats(fetchedHorsStats)
    }
  }

  useEffect(() => {
    fetchHorseStats()
    fetchCurrentUser()
  }, [])

  const checkIsUseNFTItems = () => {
    return currentUser?.id === horse?.user?.id
  }
  const checkMyHorse = () => {
    if (
      horse?.owner?.id === currentUser?.id &&
      (horse?.chain_owner_address?.toLocaleUpperCase() === currentUser?.public_address?.toLocaleUpperCase() ||
        horse?.own_status === OWNER_STATUS.LendingKey || horse?.own_status === OWN_STATUS.STABLE || horse?.own_status === GUILD_STATUS.InGuild)
    )
      return true
    else return false
  }
  const checkInGuild = () => {
    if (
      (
        ((currentUser?.id === horse?.user?.id))
      ) || (
        ((horse?.own_status === GUILD_STATUS.InGuild || horse?.own_status === GUILD_STATUS.OWNER) && !horse?.in_guild && horse?.user === null) 
      )
    )
      return true
    else return false
  } 
  
  const checkInLending = () => {
    if (
      (currentUser && currentUser?.id === horse?.user?.id)  && (horse?.own_status === OWNER_STATUS.LendingKey)
    )
      return true
    else return false
  }  
  // const checkShowValueStats = () => {
  //   if (
  //     (currentUser && currentUser?.id === horse?.user?.id)
  //   )
  //     return true
  //   else return false
  // }

  const handleCareer = (career: Career) => {
    const totalRace = career.total_number_of_races
    const firstClass = career.first_count
    const secondClass = career.second_count
    const thirdClass = career.third_count
    const tltClass = (firstClass + secondClass + thirdClass) / totalRace
    const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100
    const totalStatistic = `${totalRace} - ${firstClass}/${secondClass}/${thirdClass}`
    const winRateResult = `${Number.isInteger((firstClass / totalRace) * 100)
      ? (firstClass / totalRace) * 100
      : ((firstClass / totalRace) * 100).toFixed(2)
      }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
    const handleWinRate = () => {
      let valueDisplay = ''
      if (totalRace === 0) {
        valueDisplay = '---'
      }

      if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
        valueDisplay = '0.00% - 0.00%'
      }

      if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
        valueDisplay = winRateResult
      }

      return valueDisplay
    }
    setCarrer(totalStatistic)
    setWinRate(handleWinRate())
  }

  // get time when access first room horse
  useEffect(() => {
    if (!horse) return
    if (horse.remaining_time_to_next_energy >= 0) {
      const start_at = horse.remaining_time_to_next_energy
      setFirstTime(start_at)
    }

    setHorseHead('')
    setHorseBody('')
    setHorseLeg('')
    setHorseSkin('')
    horse?.weapon_items_equipped?.map((item: any) => {
      switch (item?.body_part) {
        case STRING_ARMOR_SKIN.HEAD: {
          return setHorseHead(STRING_ARMOR_SKIN.HEAD)
        }
        case STRING_ARMOR_SKIN.BODY: {
          return setHorseBody(STRING_ARMOR_SKIN.BODY)
        }
        case STRING_ARMOR_SKIN.LEG: {
          return setHorseLeg(STRING_ARMOR_SKIN.LEG)
        }
        case STRING_ARMOR_SKIN.SKIN: {
          return setHorseSkin(STRING_ARMOR_SKIN.SKIN)
        }
        default:
          break;
      }
    })

    setLevelUp(horse.level.exp_to_level_up - horse.level.total_exp)
    setExpCurrent(horse.level.exp_to_level_up)
    handleCareer(horse.career)
    setTotalExpHorse(horse.level.total_exp)
    setPoint(horse.stats_point_remain)
    setTmpPoint(horse.stats_point_remain)
    setHorses(
      horse?.list_horse_stats.map(object => {
        return { ...object, count: 0 }
      })
    )
    setListItemsEquipped(horse?.boost_items_equipped)
    setCanLevelUp(horse.level.can_level_up)
    setLevelHorse(horse.level)

    const fetchCareer = async () => {
      const params = { ...careerListParams, horseId: String(horse?.id) }
      const [, careerResponse] = await handleAsyncRequest<ApiResponse<GetHorseCareerResponse>>(
        horseApi.getHorseCarrerList(params)
      )

      if (careerResponse) {
        const fetchedHorse = careerResponse.data.records
        setHorseCareerList(fetchedHorse)
      }
    }

    fetchCareer()
  }, [horse])

  // time waiting
  const timer = () => setFirstTime(firstTime - 1000)
  useEffect(() => {
    const id = setInterval(timer, 1000)
    if (firstTime > 0) {
      setDisableRecovery(true)
    }
    if (firstTime <= 0) {
      setDisableRecovery(false)
      clearInterval(id)
    }
    return () => clearInterval(id)
  }, [firstTime])

  const handleDecrease = useCallback(
    (stats: ListHorseStats) => {
      if (point < (horse?.stats_point_remain || 0)) {
        const newArr = params?.map(object => {
          if (stats.stats_type === object.stats_type) {
            setPoint(object.point > 0 ? point + 1 : point)
            setUsing(object.point > 0 ? using - 1 : using)
            return { ...object, point: object.point > 0 ? object.point - 1 : object.point }
          }
          return object
        })
        setParams(newArr)
        const newHorses = horses.map((obj: ListHorseStats) => {
          if (stats.stats_type === obj.stats_type && typeof obj?.count === 'number') {
            return { ...obj, count: obj.count ? obj?.count - 1 : obj.count }
          }
          return obj
        })
        setHorses(newHorses)
      }
    },
    [params, point]
  )

  const handleIncrease = useCallback(
    (stats: ListHorseStats) => {
      if (stats.current_value + (stats?.count || 0) >= 100) return
      if (point > 0) {
        const newArr = params?.map(object => {
          if (stats.stats_type === object.stats_type) {
            setPoint(point > 0 ? point - 1 : point)
            setUsing(using >= 0 ? using + 1 : using)
            return { ...object, point: object.point + 1 }
          }
          return object
        })
        setParams(newArr)

        const newHorses = horses.map(obj => {
          if (stats.stats_type === obj.stats_type && typeof obj?.count === 'number') {
            return { ...obj, count: obj?.count + 1 }
          }
          return obj
        })
        setHorses(newHorses)
      }
    },
    [params, point, horses]
  )

  useEffect(() => {
    setTimeout(() => {
      setAnimation(false)
    }, TIME_CLOSE_STATS_MODAL)
  }, [animation])

  const handleConfirmModal = async () => {
    const paramsStats = params.filter((item: UpdateStatsParams) => item.point !== 0)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, result]: any = await handleAsyncRequest(horseApi.postUpdateStats(horseId, [...paramsStats]))
    if (result) {
      fetchHorse()
      setUsing(0)
      setParams(defaultParams)
      setAnimation(true)
      toast.success(
        <div>
          <div className='font-bold font-size-20'> {t(`${NOTIFICATION_MESSAGE}.congratulations`)}</div>{' '}
          <div className='body-content'>{t(`${NOTIFICATION_MESSAGE}.successfullyStats`)}</div>
        </div>,
        {
          position: 'bottom-right',
          autoClose: TIME_CLOSE_MODAL,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        }
      )
    }

    if (error) {
      toast.error(<div>{error?.message || error?.errors[0]?.message}</div>, {
        position: 'bottom-right',
        autoClose: TIME_CLOSE_MODAL,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })


      // setMessageError(error?.message || error?.errors[0]?.message)
      // toggleIsModalResultHorseOpen(true)
    }
    handleCloseModal()
  }

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 5
    setCurrentItems(horseCareerList.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(horseCareerList.length / 5))
  }, [itemOffset, 5, horseCareerList])

  // Invoke when user click to request another page.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * 5) % horseCareerList.length
    setItemOffset(newOffset)
  }

  const handlePickUpLevel = () => {
    setModalConfirmPickUpLevel(true)
  }

  const handleConfirmLevelUpModal = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, result]: any = await handleAsyncRequest(horseApi.postLevelUpHorse(horseId))
    if (result) {
      // useReloadCurrentPage()
      setAnimation(true)
      fetchHorse()
      const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
      if (!resultCoinUser) return
      dispatch(setCoinUser(resultCoinUser.data))
      toast.success(
        <div>
          <div className='font-bold font-size-20'>{t(`${NOTIFICATION_MESSAGE}.congratulations`)}</div>{' '}
          <div className='body-content'>{t(`${NOTIFICATION_MESSAGE}.successfullyLeveledUp`)}</div>
        </div>,
        {
          position: 'bottom-right',
          autoClose: TIME_CLOSE_MODAL,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        }
      )
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (error) {
      setMessageErrorLevelUp(error?.message || error?.errors[0]?.message)
      toggleIsModalResultHorseOpen(true)
    }
    handleCloseLevelUpModal()
  }

  const handleCloseLevelUpModal = () => {
    return setModalConfirmPickUpLevel(false)
  }

  const handleOk = () => {
    toggleIsModalResultHorseOpen(false)
  }

  const checkHorseActive = (active?: boolean) => {
    if (active === false) {
      return `class-tags`
    }
  }

  const showPopupEditName = () => {
    if (isLoading) return
    fetchHorseFee()
    setHorseName(horse?.name || '')
    toggleIsModalEditName(true)
    setHorseNameMessage('')
  }

  const _checkcurrency = (fetchedHorse: any = "") => {
    if (horse?.region === STRING_REGION.TOKYO) {
      if (fetchedHorse?.fee_for_reset_point_jp?.currency !== getCurrencyResetPoint()) {
        setChangeCurrency(true)
        return true
      }      

      if (+fetchedHorse?.fee_for_reset_point_jp?.fee > +getFeeCoinUser(getCurrencyResetPoint())) {
        setEnoughPoint(true)
        return true
      }

      if (+fetchedHorse?.fee_for_reset_point_jp?.fee <= +getFeeCoinUser(getCurrencyResetPoint()) &&
        +fetchedHorse?.fee_for_reset_point_jp?.fee !== +getFeeHorse?.fee_for_reset_point_jp?.fee) {
        return true
      }

      return false
    } else {
      if (fetchedHorse?.fee_for_reset_point_kr?.currency !== getCurrencyResetPoint()) {
        setChangeCurrency(true)
        return true
      }

      if (+fetchedHorse?.fee_for_reset_point_kr?.fee > +getFeeCoinUser(getCurrencyResetPoint())) {
        setEnoughPoint(true)
        return true
      }

      if (+fetchedHorse?.fee_for_reset_point_kr?.fee <= +getFeeCoinUser(getCurrencyResetPoint()) &&
        +fetchedHorse?.fee_for_reset_point_kr?.fee !== +getFeeHorse?.fee_for_reset_point_kr?.fee) {
        return true
      }

      return false
    }
  }

  const _handleResetPoint = async (type = '', fetchedHorse: any = '') => {
    setEnoughPoint(false)
    setChangeCurrency(false)
    switch (type) {
      case "ShowPopupReset": {
        toggleIsModalOpenReset(true)
        break;
      }
      case "ShowPopupConfirm": {
        if(_checkcurrency(fetchedHorse)) {
          return
        }
        toggleIsModalResetPointConfirm(true)
        break;
      }
      case "PostResetPoint": {
        if(_checkcurrency(fetchedHorse)) {
          return
        }
        setIsLoading(true)
        const [error, result]: any = await handleAsyncRequest(horseApi.postResetPoint(horseId))
        setIsLoading(false)
        if (result) {
          toggleIsModalResetPointConfirm(false)
          toggleIsModalOpenReset(false)
          fetchHorse()
          fetchKudaToken()
          pushNotification(t(`${NOTIFICATION_MESSAGE}.successfullyResetPoint`), true)
        }

        if (error) {
          toast.error(<div>{error?.message}</div>, {
            position: 'bottom-right',
            autoClose: TIME_CLOSE_MODAL,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        }
        break;
      }
      default:
        break;
    }
  }

  const fetchHorseFeeResetPoint = async (type = '') => {
    setEnoughPoint(false)
    setChangeCurrency(false)
    const [error, feeResetPointResponse]: any = await handleAsyncRequest<ApiResponse<FeeResetPointChangeName>>(
      horseApi.getFeeResetPoint()
    )    

    if (error) {
      toast.error(<div>{error?.message}</div>, {
        position: 'bottom-right',
        autoClose: TIME_CLOSE_MODAL,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    
    if (feeResetPointResponse) {
      const fetchedHorse = feeResetPointResponse.data
      setGetFeeHorse(fetchedHorse)
      _handleResetPoint(type, fetchedHorse)
      
    }
  }

  const showPopupResetPoint = async () => {
    await fetchHorseFeeResetPoint("ShowPopupReset")
  }

  const handleResetPointConfirm = async () => {
    await fetchHorseFeeResetPoint("ShowPopupConfirm")
  }

  const handlePostResetPoint = async () => {
    await fetchHorseFeeResetPoint("PostResetPoint")
  }

  const handleChangeName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const regex = new RegExp(REGEX_NICK_NAME)
    const nameInput = e.target.value
    if (regex.test(nameInput)) {
      setHorseName(nameInput)
      if (nameInput.length < 3) {
        setHorseNameMessage(t(`${NOTIFICATION_MESSAGE}.nameBetween3And20`))
      } else {
        setHorseNameMessage('')
      }
      if (REGEX_FIRST_SPACE.test(nameInput)) {
        setHorseNameMessage(t(`${NOTIFICATION_MESSAGE}.nameFirstSpace`))
        return
      }
      if (nameInput.match(REGEX_TWO_SPACE)) {
        setHorseNameMessage(t(`${NOTIFICATION_MESSAGE}.nameMoreThanSpace`))
      }
    } else {
      setHorseName(nameInput)
      setHorseNameMessage(t(`${NOTIFICATION_MESSAGE}.nameSpecialCharaters`))
    }
  }

  const handleEditHorseName = async () => {
    if (REGEX_LAST_SPACE.test(horseName)) {
      setHorseNameMessage(t(`${NOTIFICATION_MESSAGE}.nameFirstSpace`))
      return
    } else setHorseNameMessage('')

    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(horseApi.postHorseValidateName(horseId, { name: horseName }))
    setIsLoading(false)
    if (error) {
      setHorseNameMessage(error?.message || error?.errors[0]?.message)
      return
    }
    if (result) {
      toggleIsModalEditNameConfirm(true)
    }
  }

  const handleEditHorseNameConfirm = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(horseApi.postHorseChangeName(horseId, { name: horseName }))
    setIsLoading(false)
    if (result) {
      toggleIsModalEditNameConfirm(false)
      toggleIsModalEditName(false)
      fetchHorse()
      fetchKudaToken()
      pushNotification(t(`${NOTIFICATION_MESSAGE}.successfullyResetPoint`), true)
    }

    if (error) {
      toast.error(<div>{error?.message}</div>, {
        position: 'bottom-right',
        autoClose: TIME_CLOSE_MODAL,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
  }

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }
  const showModalSelectItem = (key: string) => {
    toggleIsSelectItemModal(true)
    setCurrentStatsSelected(key)
    setCurrentPosition('')
  }

  const fetchUserBootsItems = async () => {
    setIsLoading(true)
    const param = {
      dopingType: currentStatsSelected,
      horseId: horseId,
      position: currentPosition,
      limit: 30
    }
    const [error, result]: any = await handleAsyncRequest(horseApi.getListBoostItems(param))
    if (result) {
      setBootsTypeDetail(result?.data?.records)
      setIsLoading(false)
    }
    if (error) {
      setIsLoading(false)
      setBootsTypeDetail([])
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
  }
  const getPosition = (data: any) => {
    setCurrentPosition(data)
  }
  function handleCurrentAmountStats(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.amount)
    } else {
      return (0)
    }
  }  
  function handleCurrentImgDoping(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.boost?.img)
    } else {
      return (0)
    }
  }
  function handleCurrentOwnerDoping(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.item_owner.id)
    } else {
      return (0)
    }
  }  
  function handleReturnValuePercenStats(currentType: any) {
    const found = listItemsEquipped.find((element) => element.boost.effect_type == currentType);
    if (found) {
      return (found.boost.level)
    } else {
      return (0)
    }
  }
  function handleGetPosition(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.position)
    } else {
      return (0)
    }
  }
  function handleListGetPosition(currentType: any) {
    const found = listItemsEquipped.filter((element) => element.slot == currentType);
    if (found) {
      return (found)
    } else {
      return ([])
    }
  }
  function handleGetIdItems(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.id)
    } else {
      return (0)
    }
  }
  function handleGetIdItemsUse(currentType: any) {
    const found = listItemsEquipped.find((element) => element.slot == currentType);
    if (found) {
      return (found.id)
    } else {
      return (0)
    }
  }
  useEffect(() => {
    if (currentStatsSelected !== '' && !isChangeItem) {
      fetchUserBootsItems()
    }
  }, [currentStatsSelected])

  const handleRemoveItem = async () => {
    const [error, result]: any = await handleAsyncRequest(horseApi.deleteHorseBoosterItem(String(horseId), String(currentIdRemove)))
    if (result) {
      fetchHorse()
      pushNotification(t(`${NOTIFICATION_MESSAGE}.successfullyRemovedBootsItems`), true)
      setCurrentStatsSelected('')
    }
    if (error) {
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
    toggleIsRemoveItemOpen(false)
  }
  const handleChangeItem = async () => {
    setCurrentStatsSelected(currentStatsSelectedTemp)
    toggleIsSelectItemModal(true)
    toggleIsRemoveItemOpen(false)
    toggleIsChangeItem(true)
  }

  const handleCloseModal = () => {
    toggleIsRemoveItemOpen(false)
    toggleIsChangeItem(false)
    toggleIsModalOpen(false)
  }

  const setToggleIsSelectItemModal = () => {
    toggleIsSelectItemModal(false)
    setCurrentStatsSelected('')
    toggleIsChangeItem(false)
  }

  const showModalRemoveItem = async (id: string, type: string) => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(horseApi.getBoostItemsDetail(id))
    if (result) {
      toggleIsRemoveItemOpen(true)
      setItemDetail(result?.data)
      setCurrentIdRemove(result?.data.id)
      setIsLoading(false)
    }
    if (error) {
      setIsLoading(false)
      toggleIsRemoveItemOpen(false)
      fetchHorse()
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
    setCurrentStatsSelectedTemp(type)
    setIsLoading(false)
  }
  const fetchListBootsItemsChange = async () => {
    setIsLoading(true)
    const param = {
      dopingType: currentStatsSelected,
      horseId,
      position: currentPosition,
      limit:  30
    }
    const [error, result]: any = await handleAsyncRequest(horseApi.getListChangeBoostItems(param))
    if (result) {
      setBootsTypeDetail(result?.data?.records)
      setIsLoading(false)
    }
    if (error) {
      setIsLoading(false)
      setBootsTypeDetail([])
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
  }
  useEffect(() => {
    if (isChangeItem) {
      fetchListBootsItemsChange()
    }
  }, [isChangeItem])

  const getFeeInHorse = () => {
    if (horse?.region === STRING_REGION.TOKYO) {
      return getFeeHorse?.fee_for_changing_japan_horse_name?.fee || 0
    }
    return getFeeHorse?.fee_for_changing_korean_horse_name?.fee || 0
  }
  
  const getEnoughInHorse = () => {
    if (horse?.region === STRING_REGION.TOKYO) {
      return getFeeHorse?.fee_for_changing_japan_horse_name?.enough || false
    }
    return getFeeHorse?.fee_for_changing_korean_horse_name?.enough || false
  }

  const getFeeResetPoint = () => {
    if (horse?.region === STRING_REGION.TOKYO) {
      return getFeeHorse?.fee_for_reset_point_jp?.fee || 0
    }
    return getFeeHorse?.fee_for_reset_point_kr?.fee || 0
  }

  const getCurrencyResetPoint = () => {
    if (horse?.region === STRING_REGION.TOKYO) {
      return getFeeHorse?.fee_for_reset_point_jp?.currency || CURRENCY_TYPE.KUDA_NEW
    }
    return getFeeHorse?.fee_for_reset_point_kr?.currency || CURRENCY_TYPE.KUDA_NEW
  }

  const getEnoughResetPoint = () => {
    if (horse?.region === STRING_REGION.TOKYO) {
      return getFeeHorse?.fee_for_reset_point_jp?.enough || false
    }
    return getFeeHorse?.fee_for_reset_point_kr?.enough || false
  }

  const getFeeCoinUser = (type: any) => {
    let res: any = 0
    coinUser?.items?.map((item: any) => {
      if (item?.item_type === type) {
        res = item?.amount || 0
      }
      return
    })
    return res
  }

  const _handleClickImage = (event: React.MouseEvent, type: string, content: string) => {
    event.stopPropagation()
    setBloodlineTitle(type)
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  return (
    <HorseDetailStyled colorHorse={getColorHorse(horse?.bloodline?.color)}>
      {horse && (
        <div className='horse-detail'>
          <div className='container-horse-detail'>
            <div className='horse-detail-box position-relative'>
              <div className='horse-detail-container d-flex flex-column flex-lg-row'>
                <div className='horse-detail-left'>
                  {animation && <Animation />}
                  <div className='left'>
                    <div className='name color-white font-bold d-block d-lg-none mb-4'>{horse?.name}</div>
                    <HorseAvatar
                      horse={horse}
                      name={horse?.name}
                      avatar={horse?.avatar}
                      items={horse?.weapon_items_equipped}
                    />
                    <div className='profile__image__armor'>
                      <ImageArmorSkin
                        horseHead={horseHead}
                        horseBody={horseBody}
                        horseLeg={horseLeg}
                        horseSkin={horseSkin}
                        weaponItemsEquipped={horse?.weapon_items_equipped || []}
                        width={30}
                        height={30}
                      />
                    </div>
                    {((horse?.owner && horse?.owner?.id === currentUser?.id) ||
                      (horse?.user?.id === currentUser?.id && horse?.own_status === LENDING_STATUS.Lending)) && (
                        <div className='energy-container d-flex justify-content-center'>
                          <EnergyBar
                            maxEnergy={horse?.max_energy || 100}
                            currentEnergy={horse?.current_energy || 0}
                            customClass='w-75'
                            firstTime={firstTime}
                            disableRecovery={disableRecovery}
                            isShowPlusIcon
                            onRefresh={fetchHorse}
                          />
                        </div>
                      )}
                  </div>
                  <div className={`item-nft ${checkIsUseNFTItems() ? '' : 'only-view'} ${disableElement ? "disableDiv" : ""}`}>
                    <ItemBox horseId={horse?.id} horseItems={horse?.weapon_items_equipped} onSuccess={fetchHorse} />
                  </div>
                </div>
                <div className='horse-detail-right flex-grow-1'>
                  <div className='right'>
                    <div className='name color-white d-flex align-items-center'>
                      <div className='title'>
                        <div className='font-bold'><span className='color-yellow'>{horse.guild && `[${horse.guild?.guild_tag}]`}</span> {horse?.name}</div>
                        {horse?.own_status === OWNER_STATUS.OwnerKey && checkMyHorse() ? (
                          <div className='btn-edit' onClick={showPopupEditName}>
                            <img src={CHANGE_EDIT_ICON} alt='' width={25} />
                          </div>
                        ) : (
                          ''
                        )}
                        {horse?.active === false ? (
                          <div className='d-flex justify-content-left color-red font-size-20 mt-2'>Disabled</div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='icon-gender'>
                        <MemoizedLoadImageCommon srcImg={getImageGender(horse?.gender)} />
                      </div>
                      <div className='region'>
                        <MemoizedLoadImageCommon srcImg={getImageRegion(horse?.region)} />
                      </div>
                      <div className={`${checkHorseActive(horse?.active)} class__tag`}>
                        <ClassTag text={horse?.racing_class ?? ''} isActive={true} customClass='ms-3' />
                      </div>
                      {(horse.own_status === LENDING_STATUS.Lending || horse.own_status === LENDING_STATUS.IN_GUILD) && horse.user ? (
                        <div className='icon-borrow ms-3'>
                          <img src={ICON_BORROW} alt='' width={55} />
                        </div>
                      ) : (
                        ''
                      )}
                      {isHorseVip && (
                        <div className='vip-icon'>
                          <VipIcon />
                        </div>
                      )}

                      {!checkMyHorse() && horse.equipped_boost_item && (
                        <img src={VIEW_DOPING} alt="" className='used-doping' />)}

                      {/* new record */}
                      <div className='img_new_record'>
                        {horse?.is_new_record && (
                          <MemoizedLoadImageCommon srcImg={NEW_RECORD} />
                        )}
                      </div>
                    </div>
                    <div className='my-avatar '>
                      <img src='' alt='' className='my-img' />
                      <span className='color-white myName text-uppercase'>
                        {t(`${NOTIFICATION_MESSAGE}.owner`)}: <span className='color-yellow'>{horse?.user && getGuildTag(horse?.user?.guild_tag)}</span> {hanldeHorseOwnerName(horse)}
                      </span>
                    </div>
                    <div className='box__bloodline'>
                      {/* bloodline */}
                      <div
                        onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.BLOODLINE, `${horse?.bloodline?.name} - ${horse?.bloodline?.type}`)}
                        className='image_bloodline'
                      >
                        <MemoizedLoadImageCommon srcImg={getImageBloodline(horse?.bloodline?.name)} />
                      </div>

                      {/* run type */}
                      <div
                        onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.RUN_TYPE, horse?.run_type)}
                        className='image_run_type'
                      >
                        <MemoizedLoadImageCommon srcImg={getRunType(horse.run_type)} />
                      </div>

                      {/* characteristic */}
                      <div
                        onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.CHARACTERISTIC, horse?.characteristic_display)}
                        className='image_charitic'
                      >
                        <MemoizedLoadImageCommon srcImg={getCharacteristic(horse.characteristic_display)} />
                      </div>

                      {/* color */}
                      <div
                        onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.COLOR, horse?.bloodline?.color)}
                        className='image_color'
                      ></div>
                    </div>
                    <div className='ability-container row gy-4'>
                      {horse && sortAbilities(horse?.list_horse_ability)?.map((skill: HorseAbility, index: number) => (
                        <div className='col-12 col-sm-6' key={index}>
                          <AbilityBoxItem
                            name={skill.name_en}
                            level={skill.level} customClass='custom-bottom-frame'
                            currentStats={() => setCurrentStatsSelected(skill.name_en.toUpperCase())}
                            listTypeBoots={bootsTypeListDetail || []}
                            setCurrentStatsSelected={setCurrentStatsSelected}
                            horse_id={horseId || ''}
                            onConfirmFinal={useItemDopingSucess}
                            onCloseModalSelectItem={toggleIsSelectItemModal}
                            currentAmountStats={handleCurrentAmountStats(skill.name_en.toUpperCase())}
                            currentPosition={handleGetPosition(skill.name_en.toUpperCase())}
                            listGetPosition={handleListGetPosition(skill.name_en.toUpperCase())}
                            getPosition={getPosition}
                            currentStatsSelected={currentStatsSelected}
                            idItemsSelected={handleGetIdItems(skill.name_en.toUpperCase())}
                            idItemsUseSelected={handleGetIdItemsUse(skill.name_en.toUpperCase())}
                            onRemoveSuccess={fetchHorse}
                            isChangeItem={isChangeItem}
                            toggleIsChangeItem={toggleIsChangeItem}
                            isLoading={isLoading}
                            horse={horse}
                            isMyHorse={checkInGuild()}
                            isMyLending={checkInLending()}
                          />
                        </div>
                      ))}
                    </div>
                    <div 
                      className={`ability-container doping-plus row gy-4 ${(checkInLending() || checkInGuild()) ? '1' : 'only-view'}`}
                      >
                      <div className='col-12 col-sm-6'>
                        <div className="d-flex justify-content-between align-items-center">
                          <img src={UNION} alt='' className='frame-union' />
                          <div className="name">ITEM PLUS</div>
                          {handleCurrentAmountStats('PLUS') == 0 ? (
                            <button
                              className='push-doping new'
                              onClick={() => showModalSelectItem('PLUS')}
                              >
                              <img src={ICON_DOPING} alt='' className='' />
                            </button>
                          ) : (
                            <button
                            className={`push-doping ${(handleCurrentOwnerDoping('PLUS') === horse?.user?.id || horse?.user === null) ? '' : 'disabled' }`}
                              onClick={() => showModalRemoveItem(String(handleGetIdItems('PLUS')), 'PLUS')}
                              >
                                <img className='shadown' src={SHADOW_DOPING} alt="" />
                                <img src={handleCurrentImgDoping('PLUS')} alt='' className='d-block' />
                              {handleCurrentAmountStats('PLUS') !== 0 && (
                                <span> {handleCurrentAmountStats('PLUS')}</span>
                              )}
                            </button>
                          )}
          
                        </div>
                      </div>
                    </div>
                    {/* )} */}

                    {checkMyHorse() && (
                      <div className='point-stats d-flex justify-content-between'>
                        <div className='point'>
                          {t(`${NOTIFICATION_MESSAGE}.point`)}: {point}
                        </div>
                        <div className='using d-flex'>
                          <div>
                            {t(`${NOTIFICATION_MESSAGE}.using`)}: {using}
                          </div>
                          <div className='btn_reset_point'>
                            <ButtonResetPoint
                              onClickButton={() => showPopupResetPoint()}
                              disabled={!horse?.reset_point_stat}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='level-stats-container d-flex'>
                      {checkMyHorse() && (
                        <div className='level-container'>
                          <div className='level-bg d-flex align-items-center justify-content-center position-relative'>
                            {(horse?.level?.level || 0) < MAX_HORSE_LEVEL ? (
                              <div className='pick-up-level'>
                                {(canLevelUp === true && (horse?.own_status !== LENDING_STATUS.Lending && horse?.own_status !== OWN_STATUS.STABLE && horse?.own_status !== GUILD_STATUS.InGuild)) ? (
                                  <img
                                    src={ENABLE_LEVEL_PLUS}
                                    alt=''
                                    onClick={handlePickUpLevel}
                                    className='blink-me'
                                  />
                                ) : (
                                  <img src={DISABLE_LEVEL_PLUS} alt='' />
                                )}
                              </div>
                            ) : (
                              ''
                            )}

                            <div className='level font-bold color-white'>{horse?.level.level}</div>
                            <div className='level-text color-yellow font-bold position-absolute'>Lv.</div>
                          </div>
                          <div className='position-absolute info-dropdown'>
                            <div className='popup-exp'>
                              <img src={POPUP_EXP} alt='' />
                            </div>
                          </div>
                          <div className='position-absolute energy-exp-bar'>
                            <EnergyExpBar
                              maxEnergy={expCurrent || 100}
                              currentExp={totalExpHorse || 0}
                              customClass='w-100'
                            />
                          </div>
                          <div className='position-absolute energy-text-horse-detail color-grey'>
                            <span className='current-exp'>{`${totalExpHorse}`}</span>
                            <span>{`/${expCurrent}`}</span>
                          </div>
                          <div className='position-absolute level-up'>
                            <span className='color-white'>
                              {totalExpHorse >= expCurrent ? (
                                horse?.level.level === 20 ? (
                                  t(`${NOTIFICATION_MESSAGE}.maxLevel`)
                                ) : (
                                  t(`${NOTIFICATION_MESSAGE}.canLevelUp`)
                                )
                              ) : (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: t(`${NOTIFICATION_MESSAGE}.needToLevelUp`, { levelUp })
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className='stats-container flex-column flex-grow-1 pb-4'>
                        {horses
                          ?.sort((a, b) =>
                            STATS_TYPE.indexOf(a.stats_type) > STATS_TYPE.indexOf(b.stats_type) ? 1 : -1
                          )
                          .map(stats => (
                            <StatsBar
                              listHorsesStats={horsesStats}
                              key={stats.stats_type}
                              statsType={stats.stats_type}
                              statsRank={stats.stat_rank}
                              currentValue={stats.current_value}
                              onDecrease={() => handleDecrease(stats)}
                              onIncrease={() => handleIncrease(stats)}
                              count={stats?.count}
                              point={point}
                              // isMyHorse={false}
                              isMyHorse={checkMyHorse()}
                              horseStatus={horse?.own_status}
                              currentStats={() => setCurrentStatsSelected(stats.stats_type)}
                              listTypeBoots={bootsTypeListDetail || []}
                              token_id={horseId || ''}
                              currentAmountStats={handleCurrentAmountStats(stats.stats_type)}
                              valuePercent={handleReturnValuePercenStats(stats.stats_type)}
                              idItemsSelected={handleGetIdItems(stats.stats_type)}
                              idItemsUseSelected={handleGetIdItemsUse(stats.stats_type)}
                              onConfirm={useItemDopingSucess}
                              onRemoveSuccess={fetchHorse}
                              toggleIsChangeItem={toggleIsChangeItem}
                              isChangeItem={isChangeItem}
                            />
                          ))}
                      </div>
                    </div>
                    {checkMyHorse() && (
                      <div className='d-flex justify-content-end btn-save'>
                        <Button
                          buttonName='Save'
                          onClickButton={() => toggleIsModalOpen(true)}
                          disabled={point === horse?.stats_point_remain}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='horse-career-container'>
              <div className='horse-career-title d-flex'>
                <OneLineTitleClone text='Career' customClass='title career' contentClass='mt-2' value={carrer} />
                <OneLineTitleClone text='Win rates' customClass='title win-rates' contentClass='mt-2' value={winRate} />
              </div>
              <div className='horse-career'>
                <table className='horse-career-table w-100'>
                  {horseCareerList.length > 0 ? (
                    // <div className='d-flex header-horse text-uppercase'>
                    <thead>
                      <tr>
                        {headerRaces.map((name, index) => (
                          <th key={index} className={`header-name ${index === 0 ? 'ps-4' : ''}`}>
                            {name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                  ) : (
                    // </div>
                    <></>
                  )}
                  <tbody>
                    {currentItems.map(career => (
                      <HorseCareerItem key={career.race_horses_id} career={career} />
                    ))}
                  </tbody>
                </table>
              </div>
              {currentItems.length > 0 && horseCareerList.length > 5 && (
                <div className='paginate'>
                  <ReactPaginate
                    nextLabel='>'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel='<'
                    pageClassName='page-item'
                    pageLinkClassName='page-link'
                    previousClassName='page-item'
                    previousLinkClassName='page-link'
                    nextClassName='page-item'
                    nextLinkClassName='page-link'
                    breakLabel='...'
                    breakClassName='page-item'
                    breakLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isOpenModalConfirmPickUpLevel && (
        <HourseModalConfirmLevelUp
          onConfirm={handleConfirmLevelUpModal}
          onCloseButtonClick={handleCloseLevelUpModal}
          levelHorse={levelHorse}
          subAvatar={horse?.sub_avatar}
          remainingTimeToNextEnergy={horse?.remaining_time_to_next_energy as number}
        />
      )}

      {isModalResultHorseOpen && (
        <ResultHorseModal title={'failed!'} onOk={handleOk} message={messageErrorLevelUp} exchangeCoin={true} />
      )}

      {isModalOpen && <HorseModalConfirm onConfirm={handleConfirmModal} onCloseButtonClick={handleCloseModal} />}
      {isModalEditName && (
        <ConfirmOkModal
          onCloseButtonClick={toggleIsModalEditName}
          onConfirm={handleEditHorseName}
          title={'CHANGE HORSE’S NAME'}
          message={
            <div>
              <div className='d-flex'>{t(`${LENDING_MESSAGE}.horseName`)}</div>
              <Input
                searchValue={horseName}
                maxLength={20}
                customClass='search-horse-input'
                handleSearchValueChanged={handleChangeName}
              />
              {horseNameMessage?.length > 0 && <div className='message-error'> {horseNameMessage}</div>}
            </div>
          }
          btnOk={
            <div>
              {getFeeInHorse()} <img src={GAME_TOKEN_KUDA} width={20} />
            </div>
          }
          btnCancel='Cancel'
          isDisabled={horseNameMessage.length > 0 || !getEnoughInHorse()}
          isLoading={isLoading}
          width={150}
        />
      )}
      {isModalOpenReset && (
        <ConfirmOkModal
          onCloseButtonClick={toggleIsModalOpenReset}
          onConfirm={handleResetPointConfirm}
          title={"RESET HORSE's STAT POINTS"}
          message={
            <div>
              <div className='d-flex'>{t(`${LENDING_MESSAGE}.note_reset1`)}</div>
              <div className='d-flex'>{t(`${LENDING_MESSAGE}.note_reset2`)}</div>
              <div className='d-flex'>{t(`${LENDING_MESSAGE}.note_reset3`)}</div><br />
              <div className='d-flex'>{t(`${LENDING_MESSAGE}.note_reset4`)}</div>
              {enoughPoint && <div className='message-error'>
                {"You don't have enough "}
                {getFeeResetPoint()}
                { getCurrencyResetPoint() === CURRENCY_TYPE.KUDA_NEW ?
                  <img className="icon_currency" src={GAME_TOKEN_KUDA} /> : <img className="icon_currency" src={GAME_TOKEN_MERAH} />}
                { "to perform this action!"}
              </div>}
              {changeCurrency && <div className='message-error'>
                <div>{t(`${NOTIFICATION_MESSAGE}.system_change_coin`)}</div>
                <div>{t(`${NOTIFICATION_MESSAGE}.system_change_coin2`)}</div>
              </div>}
            </div>
          }
          btnOk={
            <div className='d-flex align-items-center'>
              {getFeeResetPoint()} 
              {getCurrencyResetPoint() === CURRENCY_TYPE.KUDA_NEW ?
                <img src={GAME_TOKEN_KUDA} width={20} /> : 
                <img src={GAME_TOKEN_MERAH} width={20} />}
            </div>
          }
          btnCancel='Cancel'
          isDisabled={!getEnoughResetPoint()}
          isLoading={isLoading}
          width={150}
        />
      )}
      {isModalEditNameConfirm && (
        <ConfirmOkModal
          onCloseButtonClick={toggleIsModalEditNameConfirm}
          onConfirm={handleEditHorseNameConfirm}
          title={'CONFIRM'}
          message={
            <div
              dangerouslySetInnerHTML={{ __html: `You need to pay ${getFeeInHorse()} KUDA to continue.<p> OK?</p>` }}
            />
          }
          btnOk='Confirm'
          btnCancel='Cancel'
          isLoading={isLoading}
        />
      )}
      {isModalResetPointConfirm && (
        <ConfirmOkModal
          onCloseButtonClick={toggleIsModalResetPointConfirm}
          onConfirm={handlePostResetPoint}
          title={'CONFIRM'}
          message={
            <div>
              <div className='d-flex'>
                {"You need to pay "}
                {getFeeResetPoint()}
                { getCurrencyResetPoint() === CURRENCY_TYPE.KUDA_NEW ?
                  <img className="icon_currency image_margin_top" src={GAME_TOKEN_KUDA} /> : <img className="icon_currency image_margin_top" src={GAME_TOKEN_MERAH} />}
                {"to continue."}
              </div><br />
              <div className='d-flex'>{"Are you sure?"}</div>
              {enoughPoint && <div className='message-error'>
                {"You don't have enough "}
                {getFeeResetPoint()}
                { getCurrencyResetPoint() === CURRENCY_TYPE.KUDA_NEW ?
                  <img className="icon_currency" src={GAME_TOKEN_KUDA} width={20} /> : <img className="icon_currency" src={GAME_TOKEN_MERAH} width={20} />}
                { "to perform this action!"}
              </div>}
              {changeCurrency && <div className='message-error'>
                <div>{t(`${NOTIFICATION_MESSAGE}.system_change_coin`)}</div>
                <div>{t(`${NOTIFICATION_MESSAGE}.system_change_coin2`)}</div>
              </div>}
            </div>
          }
          btnOk='OK'
          btnCancel='Cancel'
          isDisabled={!getEnoughResetPoint()}
          isLoading={isLoading}
        />
      )}
      {isSelectItemModal &&
        <SelectItemModal
          onConfirm={useItemDopingSucess}
          onCloseButtonClick={setToggleIsSelectItemModal}
          listTypeBoots={bootsTypeListDetail || []}
          horse_id={horseId || ''}
          position={currentPosition}
          currentStatsSelected={currentStatsSelected}
          isChangeItem={isChangeItem}
          oldItems={itemDetail}
          isLoading={isLoading}
        />
      }
      {isModalRemoveItemOpen &&
        <RemoveItemModal
          isLoading={isLoading}
          dataPreview={itemDetail}
          onRemove={handleRemoveItem}
          onChange={handleChangeItem}
          onCloseButtonClick={handleCloseModal}
        />}
      {openHorseBloodlineModal && (
        <ModalHorseBloodline
          title={bloodlineTitle}
          content={bloodlineContent}
          onClose={_handleClosePopup}
        />
      )}

      {isError &&
        <ErrorModal
          toggleIsModalOpen={toggleIsError}
          content={messageErrorRef.current}
        />}

    </HorseDetailStyled>
  )
}

export default HorseDetail
