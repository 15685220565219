/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { SearchInput } from 'shared'
import { ClassRankingFilters, DistanceFilters, DistanceOption, Horse, RaceClassNumber, RaceFieldType, TypeRankingFilters } from 'models'
import { useDebounce, useAppDispatch } from 'hooks'
import { constants } from 'apps'
import { loadingNewRecordSelector, newRecordSelector } from 'apps/redux/newRecord/selector'
import { getTopNewRecord } from 'apps/redux/newRecord/action'
import TopListRecord from 'features/Ranking/components/TopListRecord'
import Loading from 'features/Ranking/components/Loading'

import { useSelector } from 'react-redux'
import { NUMBER, STRING_REGION } from 'apps/constants'
import { TopRankingContainer } from '../styled'
import SelectRanking from 'shared/SelectRanking'

const myHorseListParams: any = {
  limit: NUMBER.TEN,
  page: NUMBER.ONE,
  search: null,
  region: STRING_REGION.TOKYO,
  fieldType: RaceFieldType.Turf,
  raceClass: RaceClassNumber.Class1,
  distance: Number((DistanceOption.DISTANCE_1000)?.replace(' m', ''))
}

const defaultTypeFilters: TypeRankingFilters = [
  {
    name: RaceFieldType.Turf,
    isActive: true
  },
  {
    name: RaceFieldType.Dirt,
    isActive: false
  }
]
const defaultClassFilters: ClassRankingFilters = [
  {
    name: RaceClassNumber.Class1,
    isActive: true
  },
  {
    name: RaceClassNumber.Class2,
    isActive: false
  },
  {
    name: RaceClassNumber.Class3,
    isActive: false
  },
  {
    name: RaceClassNumber.Class4,
    isActive: false
  },
  {
    name: RaceClassNumber.Class5,
    isActive: false
  },
  {
    name: RaceClassNumber.Class6,
    isActive: false
  },
  {
    name: RaceClassNumber.Class7,
    isActive: false
  },
  {
    name: RaceClassNumber.ClassFreeStyle,
    isActive: false
  }
]
const defaultDistanceFilters: DistanceFilters = [
  {
    name: DistanceOption.DISTANCE_1000,
    isActive: true
  },
  {
    name: DistanceOption.DISTANCE_1200,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_1400,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_1600,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_1800,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2000,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2200,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2400,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2600,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_2800,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_3000,
    isActive: false
  },
  {
    name: DistanceOption.DISTANCE_3200,
    isActive: false
  },
]

const TopRecordRanking = ({region}: any) => {
  const dispatch = useAppDispatch()
  const getNewRecordSelector = useSelector(newRecordSelector)
  const loading = useSelector(loadingNewRecordSelector)
  const [horseListParams, setHorseListParams] = useState<any>(myHorseListParams)
  const [pageCount, setPageCount] = useState<number>(0)
  const [pageCurrent, setPageCurrent] = useState<number>(horseListParams.page)
  const [listHorse, setListHorse] = useState<Horse[]>([])
  const [numberHorse, setNumberHorse] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  
  const [classFilters, setClassFilters] = useState<ClassRankingFilters>(defaultClassFilters)
  const [classSelect, setClassSelect] = useState(RaceClassNumber.Class1)
  const [typeFilters, setTypeFilters] = useState<TypeRankingFilters>(defaultTypeFilters)
  const [typeSelect, setTypeSelect] = useState(RaceFieldType.Turf)
  const [distanceFilters, setDistanceFilters] = useState<DistanceFilters>(defaultDistanceFilters)
  const [distanceSelect, setDistanceSelect] = useState(DistanceOption.DISTANCE_1000)
  
  useEffect(() => {
    setHorseListParams({
      ...myHorseListParams,
      region,
    })
    setClassSelect(RaceClassNumber.Class1)
    setTypeSelect(RaceFieldType.Turf)
    setDistanceSelect(DistanceOption.DISTANCE_1000)
    setSearchValue('')
  }, [region])

  useEffect(() => {
    dispatch(getTopNewRecord(horseListParams))
  }, [horseListParams])

  useEffect(() => {
    if (getNewRecordSelector) {
      setListHorse(getNewRecordSelector?.records)
      setPageCount(getNewRecordSelector?.total_page)
      setNumberHorse(getNewRecordSelector?.total?.toString())
    }
  }, [getNewRecordSelector])

  useEffect(() => {
    setPageCurrent(NUMBER.ONE)
    setHorseListParams({ ...horseListParams, page: NUMBER.ONE, region, search: searchValue })
  }, [debounceSearchValue])

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handlePageClick = (event: any) => {
    setPageCurrent(event.selected + 1)
    setHorseListParams({ ...horseListParams, page: event.selected + 1, region })
  }

  const handleDistanceFilterClicked = (filterName: string) => {
    const reloadDistance: any = [...distanceFilters]?.map(filter => {
      if (filter.name === filterName) {
        filter.isActive = true
        setDistanceSelect(filterName)
      } else {
        filter.isActive = false
      }
      return filter
    })      
    setDistanceFilters(reloadDistance)
    
    const newParams = { ...horseListParams, page: NUMBER.ONE, region }
    newParams.distance = Number(filterName?.replace(' m', ''))
    setHorseListParams(newParams)
  }

  const handleClassFilterClicked = (filterName: string) => {
    const reloadClass: any = [...classFilters]?.map(filter => {
      if (filter.name === filterName) {
        filter.isActive = true
        setClassSelect(filterName)
      } else {
        filter.isActive = false
      }
      return filter
    })      
    setClassFilters(reloadClass)
    
    const newParams = { ...horseListParams, page: NUMBER.ONE, region }
    newParams.raceClass = filterName
    setHorseListParams(newParams)
  }

  const handleTypeFilterClicked = (filterName: string) => {
    const reloadType: any = [...typeFilters]?.map(filter => {
      if (filter.name === filterName) {
        filter.isActive = true
        setTypeSelect(filterName)
      } else {
        filter.isActive = false
      }
      return filter
    })      
    setTypeFilters(reloadType)
    
    const newParams = { ...horseListParams, page: NUMBER.ONE, region }
    newParams.fieldType = filterName
    setHorseListParams(newParams)
  }

  return (
    <TopRankingContainer>
      <div className='head-box'>
        <SearchInput
          searchValue={searchValue}
          handleSearchValueChanged={handleSearchValueChanged}
          customClass='search-horse-input'
          placeholder={'Search'}
        />
        <div className='d-flex flex-wrap align-items-center gap-4 dropdown'>
          <SelectRanking
            dataSelect={classFilters}
            nameSelect={classSelect}
            onSelected={(filterName: string) => handleClassFilterClicked(filterName)}
            clipPath={92.7}
          />
          <SelectRanking
            dataSelect={typeFilters}
            nameSelect={typeSelect}
            onSelected={(filterName: string) => handleTypeFilterClicked(filterName)}
            clipPath={79.7}
          />
          <SelectRanking
            dataSelect={distanceFilters}
            nameSelect={distanceSelect}
            onSelected={(filterName: string) => handleDistanceFilterClicked(filterName)}
            clipPath={94.9}
          />
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <TopListRecord
          data={listHorse}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          pageCurrent={pageCurrent}
          numberHorse={numberHorse}
          debounceSearchValue={debounceSearchValue}
        />
      )}
    </TopRankingContainer>
  )
}

export default TopRecordRanking
