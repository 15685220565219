/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

interface Props {
  width?: any;
  height?: any;
  fontSize?: any;
  leftText?: any;
  topText?: any;
  topICInfo?: any;
  rigthICInfo?: any
  widthICInfo?: any
  heightICInfo?: any
}

const PriceContainer = styled.div<Props>`
  width: ${p => `${p.width}px`};
  height: ${p => `${p.height}px`};
      cursor: pointer;

  .contain__img {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 18px;
    gap: 5px;
    border: 1px solid #252B43;
    background-color: #252B43;
    // -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 8% 100%, 0 80%);
    // clip-path: polygon(0 0, 100% 0%, 100% 100%, 8% 100%, 0 80%);

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1px;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .img__text {
      color: #fff;
      z-index: 7;
      font-size: ${p => `${p.fontSize}px`};
      font-weight: bold;
      text-align: left;
    }

    .img_curency {
      position: relative;
      width: 16px;
      height: 16px;
    }

    .ic__info {
      position: absolute;
      top: ${p => `${p.topICInfo}px`};
      right: ${p => `${p.rigthICInfo}px`};
      width: ${p => `${p.widthICInfo}px`};
      height: ${p => `${p.heightICInfo}px`};
      z-index: 7;
      img {
        position: unset;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
`
export default PriceContainer
