export default {
  default: () => '/',
  home: {
    feature: () => '*',
    help: () => '/help',
    terms: () => '/terms',
    privacy: () => '/privacy'
  },
  race: {
    feature: () => 'race/*',
    scheduledRaces: () => '/scheduled-races',
    open: () => '/open',
    detail: () => '/detail/:raceId',
    result: () => '/result'
  },
  auth: {
    feature: () => 'login/*',
    login: () => '/login'
  },
  horse: {
    feature: () => 'horse/*',
    detail: () => '/detail/:horse_id',
    detailByToken: () => '/detail/:contract/:token_id',
    detailRaceView: () => '/detail/:horse_id/race/:raceId'
  },
  profile: {
    feature: () => 'profile/*',
    balance: () => '/balance',
    itemInformation: () => '/item-information',
    myProfile: () => '/my-profile'
  },
  market: {
    feature: () => 'lending/*',
    market: () => '/market',
    myAsset: () => '/my-asset',
    myLending: () => '/my-lending',
    myBorrow: () => '/my-borrow',
    detail: () => '/detail/:horse_id'
  },
  balance: {
    feature: () => 'balance/*'
  },
  maintenace: {
    feature: () => 'maintenace/*',
    detail: () => '/maintenace'
  },
  disableAccount: {
    feature: () => 'disable-account/*'
  },
  loggedAccount: {
    feature: () => '/logged-account'
  },
  notFound: {
    feature: () => '/page-not-found'
  },
  mobile: {
    feature: () => '/metamask-app'
  },
  test: {
    feature: () => '/test'
  },
  mailbox: {
    feature: () => 'mail-box/*',
    raceMail: () => '/race-mail',
    eventMail: () => '/event-mail',
    systemMail: () => '/system-mail'
  },
  specialRace: {
    feature: () => 'special-race/*',
    currentRace: () => '/current-event',
    raceHistory: () => '/event-history',
    currentFarm: () => '/current-farm',
    oldFarm: () => '/old-farm',
    detail: () => '/detail/:horse_id',
    raceDetail: () => '/race-detail/:raceId',
    historyDetail: () => '/event-history/detail/:raceId',
    historyHorseDetail: () => '/event-history/detail/:raceId/:horse_id',
    currentHorseDetail: () => '/current-event/detail/:raceId/:horse_id'
  },
  subDomainSpecialRace: {
    index: () => 'special-race',
    currentEvent: () => 'current-event',
    raceHistory: () => 'event-history',
    currentFarm: () => 'current-farm',
    oldFarm: () => 'old-farm'
  },
  guild: {
    feature: () => 'guild/*',
    createGuild: () => '/create-guild',
    joinGuild: () => '/join-guild',
    information: () => '/information',
    members: () => '/members',
    membersId: () => '/members/:guild_id',
    guildFarm: () => '/guild-farm',
    guildFarmId: () => '/guild-farm/:guild_id',
    yourHorse: () => '/your-horse',
    manage: () => '/manage',
    detail: () => '/:guild_id/horse/:horse_id',
    detailMember: () => '/:guild_id/member/:user_id',
    informationDetail: () => '/information/:guild_id',
    horseOwner: () => '/horse-owner',
    horseOwnerId: () => '/horse-owner/:guild_id',
    updateInfo: () => '/edit/update-info',
    disbandGuild: () => '/edit/disband-guild',
    inCome: () => '/edit/income',
    guildList: () => '/guild-list',
    detailOwner: () => '/:guild_id/owner/:user_id',
    myHorses: () => '/my-horses',
    myProfile: () => '/my-profile',
    horseDetailInfarm: () => '/horse/in-farm/:horse_id',
    horseDetail: () => '/my-horse/:guild_id/horse/:horse_id',
    applicationGuild: () => '/management/application',
    applicationDetailMember: () => '/:guild_id/application/:user_id/:management_id',
    userBlacklistGuild: () => '/management/user-blacklist',
    userBlacklistDetail: () => `/:guild_id/user-blacklist/:user_id/:management_id`,
    horseBlacklistGuild: () => '/management/horse-blacklist',
    horseBlacklistDetail: () => `/:guild_id/horse-blacklist/:horse_id/:management_id`,
    BalanceGuild: () => '/management/guild-balance',
  },
  ranking: {
    feature: () => 'ranking/*',
    topHorses: () => '/top-horses',
    topUsers: () => '/top-users',
    topRecordJapan: () => '/top-record-japan',
    topRecordKorea: () => '/top-record-korea'
  },
  shop: {
    feature: () => 'shop/*',
    buyItems: () => '/buy_items',
    inventoryItems: () => '/inventory_items',
    sellItems: () => '/sell_items',
    buyItemsDetails: () => '/buy_items/:id',
    inventoryItemsDetails: () => '/inventory_items/:id',
    sellItemsDetails: () => '/sell_items/:id'
  }
}
