/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react'

import { links } from 'apps'
import { STRING_ABILITY, STRING_ARMOR_SKIN, STRING_BLOODLINE_CHARACTER, STRING_GENDER, TWO_STATS_TYPE } from 'apps/constants'
import { FLASH_ICON, NEW_RECORD, VIEW_DOPING } from 'assets/images'
import { RateStar } from 'features/Horse/components'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { HorseAbility, HorseAvailable, ListHorseStats } from 'models'
import { useTranslation } from 'react-i18next'
import { ClassTag } from 'shared'
import { capitalizeOnlyFirstLetter, formatStatsRank, formatTime, getEnergyPercent, sortAbilities } from 'utils/helper'
import VipIcon from 'features/components/VipIcon'
import MemoizedImageFEMALEICON from 'shared/MemoizedImage/Female'
import MemoizedImageMALEICON from 'shared/MemoizedImage/Male'
import ImageArmorSkin from 'features/components/ImageArmorSkin'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { getAbility, getCharacteristic, getImageBloodline, getImageRegion, getRunType } from 'utils/getLinkImage'
import ChooseHorseItemStyled from './styled'
import { useToggle } from 'hooks'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'

interface ChooseHorseItemProps {
  horse: HorseAvailable
  onHorseClick?: (horseId: number) => void
  customClass?: string
  displayVip?: boolean
}

function ChooseHorseItem({ horse, onHorseClick, customClass = '', displayVip = true }: ChooseHorseItemProps) {
  const currentEnergyPercent = useMemo<number>(() => getEnergyPercent(horse.remaining_time_to_next_energy), [horse])
  const [firstTime, setFirstTime] = useState(0)
  const [disableRecovery, setDisableRecovery] = useState<boolean>(false)

  const [horseHead, setHorseHead] = useState('')
  const [horseBody, setHorseBody] = useState('')
  const [horseLeg, setHorseLeg] = useState('')
  const [horseSkin, setHorseSkin] = useState('')
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false)  

  const bloodLine = useMemo<string>(() => capitalizeOnlyFirstLetter(horse.blood_line ?? horse.bloodline?.name), [horse])
  const gender = useMemo<string>(() => capitalizeOnlyFirstLetter(horse.gender), [horse])
  const lastFiveRaces = useMemo<string>(() => horse?.last_races_position?.join('-'), [horse])
  const isHorseUsedDoping = useMemo<boolean>(() => horse?.boost_items_equipped?.length > 0, [horse])
  const { t } = useTranslation()
  const handleItemClick = (horseId: number) => () => {
    onHorseClick && onHorseClick(horseId)
  }

  useEffect(() => {
    setHorseHead(() => '')
    setHorseBody(() => '')
    setHorseLeg(() => '')
    setHorseSkin(() => '')
  }, [])

  const isHorseVip = horse?.msp_details?.msp

  useEffect(() => {
    setHorseHead(() => '')
    setHorseBody(() => '')
    setHorseLeg(() => '')
    setHorseSkin(() => '')
    setFirstTime(horse?.remaining_time_to_next_energy)

    if (horse) {
      horse?.weapon_items_equipped?.map((item: any) => {
        switch (item?.body_part) {
          case STRING_ARMOR_SKIN.HEAD: {
            return setHorseHead(STRING_ARMOR_SKIN.HEAD)
          }
          case STRING_ARMOR_SKIN.BODY: {
            return setHorseBody(STRING_ARMOR_SKIN.BODY)
          }
          case STRING_ARMOR_SKIN.LEG: {
            return setHorseLeg(STRING_ARMOR_SKIN.LEG)
          }
          case STRING_ARMOR_SKIN.SKIN: {
            return setHorseSkin(STRING_ARMOR_SKIN.SKIN)
          }
          default:
            break;
        }
      })
    }
  }, [horse])

  const timer = () => setFirstTime(firstTime - 1000)
  useEffect(() => {
    const id = setInterval(timer, 1000)
    if (firstTime > 0) {
      setDisableRecovery(true)
    }
    if (firstTime <= 0) {
      setDisableRecovery(false)
      clearInterval(id)
    }
    return () => clearInterval(id)
  }, [firstTime])

  const handleSortStatsType = (arr: ListHorseStats[]) => {
    return (
      <>
        {arr
          .sort((a, b) => (TWO_STATS_TYPE.indexOf(a.stats_type) > TWO_STATS_TYPE.indexOf(b.stats_type) ? 1 : -1))
          .map(stats => (
            <div key={stats.stats_type} className='stat-item w-50 d-flex align-items-center'>
              <span className='stat-title color-yellow'>{stats.stats_type}</span>
              <span className='stat-content color-white'>
                {stats.stat_rank !== null ? formatStatsRank(stats.stat_rank) : 'A'}
              </span>
            </div>
          ))}
        <div className='ability-wrap-content '>
          {
            sortAbilities(horse?.list_horse_ability)?.map((item: HorseAbility, index: number) => {
              return (
                <div key={index} className='ability-box d-flex justify-content-between align-items-center'>
                  <div
                    onClick={(event) => _handleClickImageAbility(event, item.name_en)}
                    className='color-white text-uppercase stat-title img__ability'
                  >
                    <MemoizedLoadImageCommon srcImg={getAbility(item.name_en)} />
                  </div>
                  <div className='level d-flex ps-2'>{generateRateLevel(3, item.level)}</div>
                </div>
              )
            })
          }
        </div>
      </>
    )
  }

  const generateRateLevel = (maxLevel: number, currentLevel: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []

    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
    }

    return rateStars
  }
  
  const openInNewTab = (event: React.MouseEvent, url: string) => {
    event.stopPropagation()
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const _handleClickImage = (event: React.MouseEvent, type: string, content: string) => {
    event.stopPropagation()
    setBloodlineTitle(type)
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  const _handleClickImageAbility = (event: React.MouseEvent, content: string) => {
    event.stopPropagation()
    switch(content?.toUpperCase()) {
      case (STRING_ABILITY.DIRT)?.toUpperCase():
      case (STRING_ABILITY.TURF)?.toUpperCase():
        setBloodlineTitle("Ability Field Type")
        break;
      case (STRING_ABILITY.LONG)?.toUpperCase():
      case (STRING_ABILITY.MEDIUM)?.toUpperCase():
      case (STRING_ABILITY.SHORT)?.toUpperCase():
        setBloodlineTitle("Ability Distance")
        break;
      default:
        break;
    }
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  return (
    <ChooseHorseItemStyled currentEnergy={currentEnergyPercent} className={customClass}>
      <div
        className='choose-horse-item d-flex align-items-center justify-content-between gap-3'
        role='button'
        onClick={handleItemClick(horse.id)}
      >
        <div className='left-container d-flex flex-column align-items-center'>
          {horse.active === false ? <div className='color-red font-bold'>disable</div> : ''}
          <div className='avatar-container d-flex align-items-center justify-content-center p-1'>
            <div className='avata__img'>
              <MemoizedLoadImageCommon srcImg={horse?.sub_avatar} alt={horse?.name} />
            </div>
          </div>
          <div className='energy-container d-flex align-items-center'>
            <img src={FLASH_ICON} alt='energy' className='energy-icon' />
            <div className='energy-bar position-relative' />
          </div>
          {disableRecovery && (
            <div>
              <span className='energy-text color-grey'>
                {t(`${NOTIFICATION_MESSAGE}.recovery`)}:{' '}
                <span className='energy-time color-white'>{formatTime(firstTime)}</span>
              </span>
            </div>
          )}

          <div className='link-container'>
            <a
              onClick={(event) => openInNewTab(event, links.horse.detail(horse?.id))}
              className='link color-primary'
            >
              {t(`${NOTIFICATION_MESSAGE}.detail`)}
            </a>
          </div>
        </div>
        <div className='mid-container flex-grow-1'>
          <div className='horse-container'>
            <div className='d-flex align-items-center'>
              <div className='name color-white font-bold'><span className='color-yellow'>{horse.guild && `[${horse.guild?.guild_tag}]`}</span> {horse.name}</div>
              <div className='ct_gender ps-2'>
                {gender === STRING_GENDER.Male ? <MemoizedImageMALEICON /> : <MemoizedImageFEMALEICON /> }
              </div>

              <div className='region'>
                <MemoizedLoadImageCommon srcImg={getImageRegion(horse?.region)} />
              </div>

              <div className='class-type ps-2'>
                <ClassTag text={horse.racing_class} isActive={true} />
              </div>

              {isHorseVip && displayVip && <VipIcon />}
              
              {isHorseUsedDoping && (
                <div className='used-doping'>
                  <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />
                </div>
              )}
              {/* new record */}
              {horse?.is_new_record && <div className='img_new_record'>
                <MemoizedLoadImageCommon srcImg={NEW_RECORD} />
              </div>}
            </div>

            <div className='extra-info d-flex align-items-center'>
              <span className='title color-grey'> {t(`${NOTIFICATION_MESSAGE}.career`)}</span>
              <span className='content color-white'>
                <span className='color-yellow'> {horse.career?.total_number_of_races} </span>{' '}
                <span>
                  {horse.career?.first_count}/{horse.career?.second_count}/{horse.career?.third_count}
                </span>
              </span>
            </div>
            <div className='extra-info d-flex align-items-center'>
              <span className='title color-grey'>{t(`${NOTIFICATION_MESSAGE}.last5Race`)}</span>
              <span className='content color-white'>{lastFiveRaces?.length ? lastFiveRaces : '---'}</span>
            </div>

            <div className='box__bloodline'>
              {/* bloodline */}
              <div
                onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.BLOODLINE, `${bloodLine} - ${horse?.bloodline?.type}`)}
                className='image_bloodline'
              >
                <MemoizedLoadImageCommon srcImg={getImageBloodline(bloodLine)} />
              </div>

              {/* run type */}
              <div
                onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.RUN_TYPE, horse?.run_type)}
                className='image_run_type'
              >
                <MemoizedLoadImageCommon srcImg={getRunType(horse?.run_type)} />
              </div>

              {/* characteristic */}
              <div
                onClick={(event) => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.CHARACTERISTIC, horse?.characteristic_display)}
                className='image_charitic'
              >
                <MemoizedLoadImageCommon srcImg={getCharacteristic(horse?.characteristic_display)} />
              </div>
            </div>

            <div className='extra-info d-flex align-items-center'>
              <ImageArmorSkin
                horseHead={horseHead}
                horseBody={horseBody}
                horseLeg={horseLeg}
                horseSkin={horseSkin}
                weaponItemsEquipped={horse?.weapon_items_equipped || []}
                width={20}
                height={20}
              />
            </div>
          </div>
        </div>
        <div className='right-container'>
          <div className='stat-container-border'>
            <div className='stat-container d-flex flex-wrap'>{handleSortStatsType(horse?.list_horse_stats)}</div>
          </div>
        </div>

        {openHorseBloodlineModal && (
          <ModalHorseBloodline
            title={bloodlineTitle}
            content={bloodlineContent}
            onClose={_handleClosePopup}
          />
        )}
      </div>
    </ChooseHorseItemStyled>
  )
}

export default ChooseHorseItem
