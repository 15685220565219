import { RootState } from "apps/store";

export const rewardJackpotSelector = (state: RootState) => state.jackpot.jackpot

export const loadingJackpotSelector = (state: RootState) => state.jackpot.loadingJackpot

export const errorJackpotSelector = (state: RootState) => state.jackpot.errorJackpot

export const newRecordSelector = (state: RootState) => state.jackpot.record

export const loadingRecordSelector = (state: RootState) => state.jackpot.loadingRecord

export const errorRecordSelector = (state: RootState) => state.jackpot.errorRecord
