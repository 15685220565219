/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

import Tabs from 'features/Ranking/components/Tabs'
import { SearchInput } from 'shared'
import { TopRankingContainer } from '../styled'
import { Horse, MyHorseListParams, RegionFilters, RegionOption, RegionOptionFilter } from 'models'
import rankingApi from 'apis/rankingApi'
import { useFetch, useIsMounted, useDebounce } from 'hooks'
import { constants } from 'apps'

import TopList from 'features/Ranking/components/TopHorseList'
import Loading from 'features/Ranking/components/Loading'
import { STRING_REGION } from 'apps/constants'
import Select from 'shared/Select'

const currentYearMonth = moment().format('YYYY-MM')

const myHorseListParams: MyHorseListParams = {
  limit: 10,
  page: 1,
  search: null,
  period: 'YEAR_MONTH',
  top: 50,
  yearMonth: currentYearMonth
}

const defaultRegionFilters: RegionFilters = [
  {
    name: 'All',
    isActive: true
  },
  {
    name: RegionOption.TOKYO,
    isActive: false
  },
  {
    name: RegionOption.SEOUL,
    isActive: false
  }
]

const TopHorse = () => {
  const isMounted = useIsMounted()
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams() as any
  const [horseListParams, setHorseListParams] = useState<MyHorseListParams>(myHorseListParams)
  const [pageCount, setPageCount] = useState<number>(0)
  const [listHorse, setListHorse] = useState<Horse[]>([])
  const [numberHorse, setNumberHorse] = useState<string>('')

  const paramsSearch = Object.fromEntries(searchParams.entries())
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const [regionFilters, setRegionFilters] = useState<RegionFilters>(defaultRegionFilters)
  const [regionSelect, setRegionSelect] = useState(paramsSearch?.region || 'Region')

  const { data: horseListResponse, loading } = useFetch(
    {
      fetcher: rankingApi.getTopHorses,
      params: {
        ...horseListParams,
        page: paramsSearch.page ? +paramsSearch.page : horseListParams.page
      }
    },
    [horseListParams]
  )

  const _onTabClick = (v: string) => {
    if (v === 'ALL_TIME') {
      delete horseListParams.yearMonth
    }
    setSearchParams({ page: 1 })
    setHorseListParams({
      ...horseListParams,
      page: 1,
      search: searchValue,
      period: v,
      ...(v === 'YEAR_MONTH' && {
        yearMonth: currentYearMonth
      })
    })
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    setSearchParams({ ...searchParams, page: 1 })
    setHorseListParams({ ...horseListParams, page: 1, search: searchValue })
  }, [debounceSearchValue])

  useEffect(() => {
    if (!horseListResponse || !isMounted()) {
      return
    }
    setListHorse(horseListResponse.records)
    setPageCount(horseListResponse.total_page)
    setNumberHorse(horseListResponse.total.toString())
  }, [horseListResponse])

  const handlePageClick = (event: any) => {
    setSearchParams({ page: event.selected + 1 })
    setHorseListParams({ ...horseListParams, page: event.selected })
  }

  const updateRegionFilters = useCallback((regionFilters: RegionFilters, clickedFilter: RegionOptionFilter) => {
    const clonedRegionFilters = [...regionFilters]

    clonedRegionFilters.forEach(filter => {
      if (filter.name === clickedFilter.name) {
        filter.isActive = true
        setRegionSelect(filter.name)
      } else {
        filter.isActive = false
      }
    })

    return clonedRegionFilters
  }, [])

  const updateParamsAfterRegionClicked = useCallback((params: MyHorseListParams, clickedFilter: RegionOptionFilter) => {
    const clonedParams = { ...params, page: 1 }
    
    if (clickedFilter.name !== 'All') {
      if (clickedFilter.name === STRING_REGION.Tokyo) {
        clonedParams.region = STRING_REGION.TOKYO
      } else {
        clonedParams.region = STRING_REGION.SEOUL
      }
    } else {
      delete clonedParams.region
    }

    return clonedParams
  }, [])

  const handleRegionFilterClicked = useCallback(
    (filterName: string) => () => {
      const clickedFilter = regionFilters.find(filter => filter.name === filterName)

      if (!clickedFilter) return

      const newRegionFilters = updateRegionFilters(regionFilters, clickedFilter)
      const newParams = updateParamsAfterRegionClicked(horseListParams, clickedFilter)

      setRegionFilters(newRegionFilters)
      setHorseListParams(newParams)
    },
    [regionFilters, horseListParams]
  )

  return (
    <TopRankingContainer>
      <div className='head-box'>
        <SearchInput
          searchValue={searchValue}
          handleSearchValueChanged={handleSearchValueChanged}
          customClass='search-horse-input'
          placeholder={'Search'}
        />
        <div className='d-flex flex-wrap align-items-center gap-4 dropdown'>
          <Select
            dataSelect={regionFilters}
            nameSelect={regionSelect}
            onSelected={(filterName: string) => handleRegionFilterClicked(filterName)()}
          />
        </div>
      </div>
      <Tabs onTabClick={_onTabClick} />
      {loading ? (
        <Loading />
      ) : (
        <TopList
          data={listHorse}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          paramsSearch={paramsSearch}
          numberHorse={numberHorse}
          debounceSearchValue={debounceSearchValue}
        />
      )}
    </TopRankingContainer>
  )
}

export default TopHorse
