import styled from 'styled-components'

interface SelectBoxStyleProps {
  clipPath?: number
}

const SelectBoxStyled = styled.div<SelectBoxStyleProps>`
  min-width: 125px;
  position: relative;
  .select-box-customs {
    color: #8d8d8d;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    position: relative;
    width: 100%;
    height: 32px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 3px;
    img {
      transform: scaleY(-1);
      width: 100%;
      height: inherit;
    }
    span.select-name {
      position: absolute;
      display: flex;
      top: 5px;
      text-align: center;
      width: 90%;
      justify-content: center;
    }
    img.select-drop-icon {
      position: absolute;
      width: 25px;
      height: 25px;
      right: 50%;
      transform: translateX(220%);
      top: 5px;
    }
    img.up-down-icon {
      transform: scaleY(-1) translateX(220%);
    }
  }

  ul.select-content-dropdown {
    display: block;
    z-index: 1000;
    position: relative;
    top: 2px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #8d8d8d;
    margin: 0;
    padding: 1px;
    list-style: none;
    clip-path: ${({ clipPath }) => `polygon(100% 0, 100% ${clipPath}%, 82% 100%, 0 100%, 0 0)`};

    li {
      width: 99.8%;
      color: #ffffff;
      padding: 6px 20px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      background-color: #191d2c;
      cursor: pointer;
    }
    li.active {
      color: #f55555;
      background: linear-gradient(90deg, rgba(27,29,38,1) 0%, rgba(74,75,81,1) 50%, rgba(27,29,38,1) 100%);
    }

    li:hover {
      background: linear-gradient(90deg, rgba(27,29,38,1) 0%, rgba(74,75,81,1) 50%, rgba(27,29,38,1) 100%);
    }

    .space_content {
      width: 99.8%;
      height: 15px;
      background-color: #191d2c;
      cursor: default;
    }
    .space_content_last {
      width: 99.8%;
      height: 20px;
      background-color: #191d2c;
      cursor: default;
      z-index: 1001;
      clip-path: polygon(100% 0, 100% 0%, 82% 100%, 0 100%, 0 0);
    }
  }
`

export default SelectBoxStyled
