/* eslint-disable @typescript-eslint/no-explicit-any */
import { CAREER_BIRU_OLD_ICON, CAREER_MERAH_OLD_ICON, GAME_TOKEN_KUDA, GAME_TOKEN_MERAH } from "assets/images"
import { IMG_INFO } from 'assets/images'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import PriceContainer from "./styled"
import { useEffect, useState } from "react"
import { useToggle } from "hooks"
import ModalJackpotDetail from "features/components/ModalJackpotDetail"

interface RaceTypeProps {
  jackpot?: any
  newRecord?: any
  price: any
  type: string
  horseId?: any
  raceId?: any
  width?: number
  height?: number
  fontSize?: number
  leftText?: number
  topText?: number
  topICInfo?: number
  rigthICInfo?: number
  posTooltip?: boolean
  widthICInfo?: number
  heightICInfo?: number
}

function PrizeJackpotColumn({ jackpot, newRecord, price, type, raceId = 0, horseId = 0,  width = 110, height = 35, fontSize = 17, leftText = 35, topText = 9, topICInfo = 0, rigthICInfo = 3, widthICInfo = 14, heightICInfo = 14 }: RaceTypeProps) {
  const [reqestJackpot, setReqestJackpot] = useState<any>("")
  const [linkImg, setLinkImg] = useState("")
  const [openJackpotModal, toggleOpenJackpotModal] = useToggle(false)  

  useEffect(() => {
    if (jackpot) {
      let IMG = ""
      switch (type) {
        case 'BIRUOLD': {
          IMG = CAREER_BIRU_OLD_ICON
          break
        }
        case 'MERAHOLD': {
          IMG = CAREER_MERAH_OLD_ICON
          break
        }        
        case 'KUDA': {
          IMG = GAME_TOKEN_KUDA
          break
        }
        case 'MERAH': {
          IMG = GAME_TOKEN_MERAH
          break
        }
        default:
          break
      }
      setLinkImg(IMG)
      
      const jackpotRW = jackpot?.jackpot_detail?.find((element: any) => jackpot?.position === element?.position)
      const tmpNewRecord = newRecord?.new_record_detail?.find((element: any) => newRecord?.horse_id === element?.horse_id)

      const reqest: any = {
        poolReward: {
          price,
          icon: IMG
        },
        jackpotReward: jackpotRW,
        newRecord: tmpNewRecord
      }
      setReqestJackpot(reqest)
    }
  }, [jackpot])

  const handleClickOpen = (event: React.MouseEvent) => {
    event.stopPropagation()
    toggleOpenJackpotModal(true)
  }

  const handleClosePopup = () => {
    toggleOpenJackpotModal(false)
  }

  return (
    <>
      <PriceContainer
        width={width}
        height={height}
        fontSize={fontSize}
        leftText={leftText}
        topText={topText}
        topICInfo={topICInfo}
        rigthICInfo={rigthICInfo}
        widthICInfo={widthICInfo}
        heightICInfo={heightICInfo}
      >
        <span onClick={(event) => handleClickOpen(event)} className="contain__img">
          {price ? <>
            <span className="img__text">{ price }</span>
            <div className='img_curency'>
              <MemoizedLoadImageCommon srcImg={linkImg} />
            </div>
            
          </> : "---"}
          {reqestJackpot?.jackpotReward?.rewards_details?.length || reqestJackpot?.newRecord?.rewards_details?.length ? <>
            <span className="ic__info">
              <img src={IMG_INFO} alt='' className='info' />
            </span>
          </> : ""}
        </span>
      </PriceContainer>
      {openJackpotModal && (
        <ModalJackpotDetail
          onClose={handleClosePopup}
          jackpot={reqestJackpot}
          raceId={raceId}
          horseId={horseId}
        />
      )}
    </>
  )
}

export default PrizeJackpotColumn
